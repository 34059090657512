import React from 'react';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import './../Information.css';
import informationImage from './shuttle.jpg'; 

import mon_tues from './shuttle_monday_tuesday_for_web.png'; 
import wed from './shuttle_wed-sat_for_web.png'; 
import sun from './shuttle_sunday_for_web.png'; 
function Shuttle() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>SHUTTLE</h1>
          </div>
        </div>
      </header>
      <main>
    
        <section className="lineup-section">
        <h1 className='norse-font'>SHUTTLE</h1>
        <p>The beer run shuttle will take you to and from Camp Utgard where you can purchase what you need for the camping experience! They have everything from hot food, to supplies and beer. So this is the perfect way to stock up on everything, without having to walk back and forth. </p>
         <p>Festival shuttle pass costs 99,- NOK ( for the whole festival ) and will be available for purchase in the info tent at Camp Utgard and at the ticket booth!</p>
       
       <p>If you wish to travel to Horten City centre, Tønsberg, Skoppum, Åsgårdstrand check out this page with information about <a href="https://midgardsblot.no/information/bus">VKT.no</a></p>
       
        </section>
       <section className='red-section'>
       <img src={mon_tues} style={{ width: '100%' }} alt="monday and tuesday schedule" />
       <img src={wed} style={{ width: '100%' }} alt="monday and tuesday schedule" />
       <img src={sun} style={{ width: '100%' }} alt="monday and tuesday schedule" />
       </section>
      
      </main>
      <Footer />
    </div>
  );
}

export default Shuttle;
