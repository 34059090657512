import React from 'react';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import './../Information.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import informationImage from './park.JPG'; 

library.add(fas, fab, far);

function Parking() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>PARKING</h1>
          </div>
        </div>
      </header>
      <main>
        <section className="lineup-section">
          <h1 className='norse-font'>FESTIVAL PARKING</h1>
   
          <p><b>The cost for parking is 266 NOK/day per car.</b></p>
          <p><b>You can buy a Wed-Sunday pass for 999 NOK per car.</b></p>
          <p>Payment can be made by card when you arrive.</p>
          <p>Drive towards the Midgard Viking Center and follow the signs for parking.</p>
        </section>
        
      </main>
      <Footer />
    </div>
  );
}

export default Parking;
