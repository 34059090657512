import React from 'react';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import './../Information.css';

import informationImage from './car.jpg'; 



function Car() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>TRAVEL BY CAR</h1>
          </div>
        </div>
      </header>
      <main>
    
        <section className="lineup-section">
        
         <p>Midgardsblot takes place next to the Viking Age grave mounds in Borre, near Horten, in Vestfold County, Norway. It is right by the beach (Oslo Fjord) and near the Midgard Vikingsenter Museum.
</p><p>
<strong>ADDRESS:</strong> Birkelyveien 9, 3184 Borre, Norway
</p><p>
Traveling to the festival site is quick and easy with both car and public transportation.</p>
<a href="/information/parking" className="read-more-button">Parking at the festival</a> 
        </section>
        <div className='map-container'>
        <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4063.7619805463846!2d10.466732!3d59.385012999999994!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4646b48dc1449661%3A0xe176a191a21903fa!2sBirkelyveien%209%2C%203184%20Borre!5e0!3m2!1sen!2sno!4v1719753400718!5m2!1sen!2sno"
      width="350"
      height="350"
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
      title="Midgard Viking Center Location Map"

      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
      </div>
      </main>
      <Footer />
    </div>
  );
}

export default Car;
