import React from 'react';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import './../Information.css';
import informationImage from './payment.jpg'; 

function Payment() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>Payment</h1>
          </div>
        </div>
      </header>
      <main>
        <section className="lineup-section">
          <h1 className='norse-font'>How to pay while at Midgardsblot</h1>
          <p>Here's everything you need to know about payment options at the festival area. We're excited to use the Espos payment system again this year, ensuring a seamless and convenient experience for all your purchases.</p>
        </section>
        <section className="red-section">
          <h2 className='norse-font'>Paying with Card</h2>
          <p>You can use your card just like you would at any store. The following cards are supported by Espos:</p>
          <ul style={{ textAlign: 'center', listStyleType: 'none', padding: 0 }}>
  <li>Bankaxept</li>
  <li>Visa</li>
  <li>Mastercard</li>
  <li>Diners</li>
  <li>Amex</li>
  <li>JCP</li>
  <li>Union Pay</li>
  <li>DiggiPay</li>
</ul>

          <p>You can also use Google Pay or Apple Pay as long as the linked card supports one of the above technologies.</p>

          <h2 className='norse-font'>Cash to Card or App</h2>
          <p>We understand that some attendees may have high fees on international transactions or prefer to use cash. While cash payments are not accepted at our bars, food stalls, or Kaupangr vendors, you can exchange your cash for a cashless payment card (with a 50 NOK fee) or upload the amount to your DiggiPay app (internet connection required).</p>
          <p>Visit the Midgardsblot merchandise stand on the festival site to transfer your cash (NOK) into a DiggiPay card or app. We recommend downloading and registering on the app before arriving for a smoother experience.</p>

          <h2 className='norse-font'>Withdrawing After the Festival</h2>
          <p>To withdraw any remaining balance, visit Diggipay.no to transfer the funds from your card or app.</p>

          <h2 className='norse-font'>Folkvangr Viking Market</h2>
          <p>The Folkvangr Viking Market is located in the Folkvangr Viking Camp. The Viking vendors are individual sellers who bring their own payment systems.</p>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Payment;
