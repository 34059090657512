import React from 'react';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';


import informationImage from './activities.jpg'; 
import workshop from './Midgardsblot-2024-banner-kvadrat-metal-vocal-workshop.jpg';
import talesman from './Midgardsblot-2024-banner-kvadrat-Tim-Talesman.jpg';
import nyanser from './Midgardsblot-2024-banner-kvadrat-Nyanser-Av-Svart.jpg';
import nyttland from './Midgardsblot-2024-banner-kvadrat-Nytt-Land-Story.jpg';
import doctors from './Midgardsblot-2024-banner-kvadrat-Doctors.jpg';
import openingblot from './Midgardsblot-2024-banner-kvadrat-Folket-Bortafor-Nordavinden 2.jpg';
import peter from './Midgardsblot-2024-banner-kvadrat-Franzen.jpg';
import herbs from './Midgardsblot-2024-banner-kvadrat-Herbs.jpg';
import gustav from './Midgardsblot-2024-banner-kvadrat-Holberg.jpg';
function Activities() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>Lectures & Other Activities</h1>
          </div>
        </div>
      </header>
      <main>
    
        <section className="lineup-section">
        
        <p>Here you can read more about the different activities during Midgardsblot like tastings, lectures and workshops!</p>
       
</section>
<section className="info-section">
<div className="info-grid">
<div>
<img src={peter} alt="Information" className="information-image" />
    
    <hr className='sep'></hr>
    <h3 className='norse-font'>INTERVIEW WITH PETER FRÁNZEN</h3>
    <p style={{ fontSize: '0.8em' }}>We're excited to announce that PETER FRANZÉN, in addition to joining Hindarfjäll on stage, will participate in an exclusive interview inside the Gildehall.</p>

<p  style={{ fontSize: '0.8em' }}>The conversation will be expertly guided by Jonathan Selzer, a seasoned music journalist with a distinguished career, including contributions to Terrorizer, Melody Maker, Kerrang!, and Metal Hammer.</p>
</div>
<div>
<img src={nyttland} alt="Information" className="information-image" />
    
    <hr className='sep'></hr>
    <h3 className='norse-font'>NYTT LAND: Story of Nishan Shaman</h3>
    <p style={{ fontSize: '0.8em' }}>With their voices and music Nytt Land will lead you to through the ancient Tungus-Manchu legend “Nishan Saman-i Bithe” - the Legend of the Nishan Shaman.
This tale, one of the oldest and most complete sources on Siberian shamanism, offers a unique glimpse into the world of ancient shamanic rituals.
    </p>
    
</div>
<div>
<img src={nyanser} alt="Information" className="information-image" />
    
    <hr className='sep'></hr>
    <h3 className='norse-font'>NYANSER AV SVART</h3>
    <p style={{ fontSize: '0.8em' }}>Join us at Midgardsblot for an exclusive talk on the roots of Norwegian black metal, featuring insights from Harald Fossberg, author of "666 Shades of Black."
    </p>
    
</div>
<div>
<img src={doctors} alt="Information" className="information-image" />
    
    <hr className='sep'></hr>
    <h3 className='norse-font'>DOCTORS WITHOUT BORDERS: THE WORLDS AMBULANCE</h3>
    <p style={{ fontSize: '0.8em' }}>We are thrilled to announce that on Friday inside Gildehallen, there will be an insightful lecture by Doctors Without Borders ( Leger Uten Grenser ) titled "Doctors Without Borders - The World's Ambulance." They will talk about how they work, why they speak out in conflicts, and why they try to shed light on the forgotten crises around the world.
    </p>
    
</div>
<div>
  
<img src={gustav} alt="Information" className="information-image" />
    
    <hr className='sep'></hr>
    <h3 className='norse-font'>STORY TELLING by Gustav Holberg</h3>
    <p style={{ fontSize: '0.8em' }}>

    From the mystical caves of Jotunheimr, Gustav Holberg, Norway’s most electrifying storyteller! With his powerful words and dynamic energy, Gustav weaves vivid tales that leap from his mind to yours.
    Legend says he’s inherited his silver tongue from his ancestor, the trickster god Loki. Don’t miss this chance to be mesmerized by the stories of the Aesir and Jotuns. Experience the ancient and magical art of storytelling.
    </p>
    
</div>
<div>
  
<img src={herbs} alt="Information" className="information-image" />
    
    <hr className='sep'></hr>
    <h3 className='norse-font'>From Farmer to Viking: How Birch Tar Changed Our World</h3>
    <p style={{ fontSize: '0.8em' }}>Join us for an enlightening lecture at the Gildehall! Discover the fascinating history, intricate production process, and diverse uses of birch tar—a crucial substance that played a significant role in the Viking Age. Explore why birch tar was so important for Viking conquests and how it shaped the world as we know it.</p>
    
</div>
<div>
<img src={workshop} alt="Information" className="information-image" />
    
    <hr className='sep'></hr>
    <h3 className='norse-font'>EXTREME METAL VOCAL WORKSHOP</h3>
    <p style={{ fontSize: '0.8em' }}>Join us for an extreme vocals workshop with Katarina Kjartansdatter Lembach-Beylegaard, an authorized CVT-vocal coach specializing in rock and metal vocals. Learn to scream and growl safely without damaging your voice, whether you're a total beginner or an experienced screamer.
    </p>
    
</div>
<div>
<img src={talesman} alt="Information" className="information-image" />
    
    <hr className='sep'></hr>
    <h3 className='norse-font'>STORY TELLING BY TIM TALESMAN</h3>
    <p style={{ fontSize: '0.8em' }}>For years, Tim has captivated audiences at festivals and events with his love for old Norse/Germanic myths and sagas. His passion shines through every tale he tells. But Tim doesn't just bring the ancient stories to life – he also creates new myths that honor and expand these timeless traditions.
    </p>
    
</div>
<div>
<img src={openingblot} alt="Information" className="information-image" />
    
    <hr className='sep'></hr>
    <h3 className='norse-font'>OPENING BLOT: BY FOLKET BORTAFOR NORDAVINDEN</h3>
    <p style={{ fontSize: '0.8em' }}>
    The opening ritual incorporates elements of a Viking Age blot, featuring wooden statues representing the ancient gods and goddesses, along with sacrificial animal blood. The Blot Ceremony is non-religious and is as deeply personal as it is communal, welcoming everyone to share in this profound experience.
    </p><p>Access only with 4-day festival pass</p>
    
</div>


      </div>
</section>

      </main>
      <Footer />
    </div>
    
  );
}

export default Activities;
