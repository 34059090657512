import React from 'react';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';

import informationImage from './GRIMFROST.jpg';

function Grimfrost() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>GRIMFROST</h1>
          </div>
        </div>
      </header>
      <main>
        <section className="lineup-section">
          <h1 className='norse-font'>GRIMFROST AT MIDGARDSBLOT</h1>
          <p>
            Grimfrost is set to make a grand appearance at Midgardsblot, bringing an immersive Viking experience to the festival. Here's what to expect:
          </p>
          <a href="https://grimfrost.com/" target="_BLANK" rel="noreferrer" className="read-more-button">WEBSITE</a> 
        </section>
        <section className="red-section">
          <h2 className='norse-font'>Ragnar Lothbrok's Ship</h2>
          <hr className='sep'></hr>
          <p>
            One of the highlights will be the <strong>longship used by Ragnar Lothbrok</strong> in the "Vikings" series. This iconic vessel will be displayed next to Grimfrost's merchandise tent in the <strong>Kaupangr area</strong>, offering a unique opportunity for attendees to get up close to a piece of television history.
          </p>

          <h2 className='norse-font'>Hindarfjäll featuring Peter Franzen - Release and Signing Session</h2>
          <hr className='sep'></hr>
          <p>
            On <strong>Wednesday</strong>, Grimfrost will host a special event at their tent: the <strong>release of Hindarfjäll's latest album</strong>.
            <br/>Buy the new album at a discounted festival price and have it signed.<br/> Peter Franzen will also be present to sign the previous Hindarfjäll album on which he appears with the song from Vikings.


          </p>

          <h2 className='norse-font'>Holmgang Duels</h2>
          <hr className='sep'></hr>
          <p>
            Visitors can experience the intensity of <strong>Holmgang fighting</strong> every day. These duels are based on historical Viking practices, offering participants a chance to step into a "life and death" scenario. While the Hird fighters engage in full-contact bouts with extensive protection, spectators are invited to try their hand at Holmgang using <strong>latex weapons</strong>, with sessions held daily at 17:00.
          </p>

          <h2 className='norse-font'>Shield Wall Battles</h2>
          <hr className='sep'></hr>
          <p>
            In addition to Holmgang duels, Grimfrost will host <strong>shield wall battles</strong> in front of the main stage from Thursday to Saturday. These battles will showcase the brutal and efficient combat techniques used by Vikings, providing an exhilarating spectacle for festival-goers.
          </p>

          <h2 className='norse-font'>Grimfrost Merchandise and More</h2>
          <hr className='sep'></hr>
          <p>
            Alongside these activities, attendees can explore Grimfrost's wide range of historical and modern Viking-inspired merchandise at their tent. From authentic replicas to modern apparel, there's something for every Viking enthusiast.
          </p>

          
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Grimfrost;
