import React from 'react';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';

import informationImage from './6.jpg'; 
import crewmap from './CREW MAP.jpg'; 
function Information() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>EINHERJAR GUIDE 2024</h1>
          </div>
        </div>
      </header>
      <main>
        <section className="lineup-section">
          <p></p>
        </section>

        <section className="white-section">
          <div className="table-of-contents">
          <h2 className='norse-font'>TABLE OF CONTENTS</h2>
    <a href="#festival-values">Festival Values</a><br/>
    <a href="#general-information">General Information</a><br/>
    <a href="#accreditation">Accreditation</a><br/>
    <a href="#wristband-access">Wristband Access</a><br/>
    <a href="#no-show">No Show</a><br/>
    <a href="#get-in">Get In</a><br/>
    <a href="#meeting-points">Meeting Points</a><br/>

    <a href="#hospitality">Hospitality</a><br/>
    <a href="#wifi">Wi-Fi</a><br/>
    <a href="#work-tasks">Work Tasks</a><br/>
    <a href="#work-clothes">Work Clothes</a><br/>
    <a href="#info-meeting">Info Meeting (Volunteers Only)</a><br/>
    <a href="#contact-information">Contact Information</a><br/>
    <a href="#crew-shuttle">Extra crew shuttle</a><br/>
    <a href="#gladheimr">Gladheimr Camp Guidelines & Travel Advice for Volunteers</a><br/>
    <a href="#utgard">Utgard Camp Guidelines for Volunteers</a><br/>
            
            


            
          </div>
          <div className='left-align' id="festival-values">
  <h2 className='norse-font'>Festival Values</h2>
  <hr className='sep'></hr>
  <p>We want everyone who attends our festival – guests, volunteers, crew, artists, vendors, and press – to have a good time and feel safe. As one of our volunteers, we appreciate that you do your utmost to contribute to our inclusive atmosphere and show that you care for our beautiful environment. Please respect that our community is non-political and non-prejudicial. Be inclusive; this is our common home.</p>
</div>
          <div className='left-align' id="general-information">
  <h2 className='norse-font'>General Information</h2>
  <ul>
    <li>Please have your phone available at all times. Keep it switched on and be logged in to the channel where your Team leader communicates with you.</li>
    <li>If you don’t understand a message or the information is confusing – ask.</li>
    <li>Never assume anything unless it has been confirmed by someone in charge.</li>
    <li>Always follow the instructions given by your Team leader and Crew Manager.</li>
    <li>Remember that the festival is not responsible for your belongings and cannot be held liable for any accidents that may occur. You need to have your own travel insurance.</li>
    <li>Our working language is English.</li>
    <li>If the info you’re seeking is not written in this guide, please check <a href="https://www.midgardsblot.no" target="_blank" rel="noopener noreferrer">www.midgardsblot.no</a>. Your question may have already been addressed here.</li>
  </ul>
</div>

          <div className='left-align' id="accreditation">
  <h2 className='norse-font'>Accreditation</h2>
  <hr className='sep'></hr>
  <p>You can pick up your accreditation at the Accreditation booths located next to the Midgard Center parking lot. There is a separate booth for volunteers only.  This is where you will pick up your wristband, Crew-pass and T-shirt. Please remember to bring a valid photo ID. You cannot pick up accreditation for other volunteers as it is personal.</p>
    <p><strong>OPENING HOURS:</strong></p>
  <p>Tuesday 10.00 - 20.00</p>
  <p>Wed-Sat 10.00 - 22.00</p>
</div>
<div className='left-align' id="wristband-access">
  <h2 className='norse-font'>Wristband Access</h2>
  <hr className='sep'></hr>
  <p>Your festival wristband is valid for all four days (Wed-Thurs-Fri-Sat) unless you have made a specific work agreement with the Crew Manager (some volunteers work only one day and receive a 1-day pass as compensation). This wristband gives you access to the festival with arena concerts but does not include the separate programs by our partners, such as Saga Oseberg Viking ship tour, Midgards talks, etc. Volunteers working backstage and as Stagehands need a second wristband for exclusive backstage access. You will receive it from your Team leader when you arrive for your shifts.</p>
  <p>Access to the arena without a wristband before Wednesday is not permitted unless you are registered as a working crew member (Delta Force or production crew). All crew working the days before/after the festival need to check in with their Team leader upon arrival.</p>
</div>
<div className='left-align' id="no-show">
  <h2 className='norse-font'>No Show</h2>
  <hr className='sep'></hr>
  <p>If you pick up your accreditation on Tuesday and fail to show up for work on the days you are scheduled, we will charge you for the full festival pass and you will not be accepted as a volunteer again. Remember that your contract is binding.</p>
</div>
<div className='left-align' id="get-in">
  <h2 className='norse-font'>Get In</h2>
  <hr className='sep'></hr>
  <p>Your get-in time is when your Team leader expects you to be present and ready for work, which means the time you are expected to be at your Meeting Point. Please allow for the appropriate time needed to change clothes, go to the toilet, etc. before your get-in time.</p>
  <p>If you are running late to your shift, please notify your Team leader. If you are sick or otherwise cannot show up for work, call your Team leader or Volunteer coordinator – and please don’t wait until 5 minutes into your shift! If you know the day before that you might call in sick the next day, contact us immediately. This is important because we need to find a replacement to cover for you! <strong>PS. A hangover, however unpleasant, is NOT a valid cause for calling in sick.</strong></p>
</div>
<div className='left-align' id="meeting-points">
  <h2 className='norse-font'>Meeting Points</h2>
  <hr className='sep'></hr>
  <p>If you start working before doors open at 14:00 (day shift), meet outside <strong>Prod.gate A (Birkelyveien)</strong> where your Team leader will come and pick you up.</p>
  <p>If you start working after 14:00, enter through the <strong>Main festival entrance</strong> using the Crew Fast track. Go to the Crew Lounge and find the designated area for Volunteer meetup. Your Team leader will come and pick you up.</p>
  <img src={crewmap} alt="map" style={{ width: '70%' }} />


</div>

<div className='left-align' id="hospitality">
  <h2 className='norse-font'>Hospitality</h2>
  <hr className='sep'></hr>
  <p> You will get food when your shift crosses over/into a meal, and you will receive your food ticket from your Team leader.</p>
  <p>Crew Lounge opening hours: 10:00-23:00</p>
  <p><b>Remember that you do not have access to the crew catering unless you are registered in a working team that day.</b></p>
  <h3>Meal Times</h3>
  <div style={{ overflowX: 'auto' }}>
  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
    <thead>
      <tr>
        <th style={{ padding: '10px 20px', border: '1px solid #ddd' }}>Date</th>
        <th style={{ padding: '10px 20px', border: '1px solid #ddd' }}>Lunch</th>
        <th style={{ padding: '10px 20px', border: '1px solid #ddd' }}>Dinner</th>
        <th style={{ padding: '10px 20px', border: '1px solid #ddd' }}>Location</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style={{ padding: '10px 20px', border: '1px solid #ddd' }}>Sat 10. - Sun 11.</td>
        <td style={{ padding: '10px 20px', border: '1px solid #ddd' }}>12.00 – 14.00</td>
        <td style={{ padding: '10px 20px', border: '1px solid #ddd' }}>16.00 – 20.00</td>
        <td style={{ padding: '10px 20px', border: '1px solid #ddd' }}>Gildehallen</td>
      </tr>
      <tr>
        <td style={{ padding: '10px 20px', border: '1px solid #ddd' }}>Mon 12.- Tues 13.</td>
        <td style={{ padding: '10px 20px', border: '1px solid #ddd' }}>12.00 – 14.00</td>
        <td style={{ padding: '10px 20px', border: '1px solid #ddd' }}>16.00 – 20.00</td>
        <td style={{ padding: '10px 20px', border: '1px solid #ddd' }}>Crew Lounge</td>
      </tr>
      <tr style={{ backgroundColor: 'lightgreen', fontWeight: 'bold' }}>
        <td style={{ padding: '10px 20px', border: '1px solid #ddd' }}>Wed 14. - Sat 17.</td>
        <td style={{ padding: '10px 20px', border: '1px solid #ddd' }}>11.00 – 13.00</td>
        <td style={{ padding: '10px 20px', border: '1px solid #ddd' }}>16.00 – 20.00</td>
        <td style={{ padding: '10px 20px', border: '1px solid #ddd' }}>Crew Lounge</td>
      </tr>
      <tr>
        <td style={{ padding: '10px 20px', border: '1px solid #ddd' }}>Sun 18. - Tues 20.</td>
        <td style={{ padding: '10px 20px', border: '1px solid #ddd' }}>12.00 – 14.00</td>
        <td style={{ padding: '10px 20px', border: '1px solid #ddd' }}>16.00 – 18.00</td>
        <td style={{ padding: '10px 20px', border: '1px solid #ddd' }}>Gildehallen</td>
      </tr>
    </tbody>
  </table>
</div>

  

</div>

<div className='left-align' id="wifi">
  <h2 className='norse-font'>WI-FI</h2>
  <hr className='sep'></hr>
  <p><strong>Crew Lounge Wifi:</strong></p>
<p>CrewLounge</p>

<p><strong>Password</strong></p>
<p>Workhard2024</p>

<p><strong>Gladheimr WiFi:</strong></p>
<p>Contact Camp HQ!</p>
</div>

          <div className='left-align' id="work-tasks">
            <h2 className='norse-font'>Work Tasks</h2>
            <hr className='sep'></hr>
            <p>Your Team leader will brief you on your tasks before you start working, in your Team-specific group or at the start of your shift. Please pay close attention to the instructions provided by your Team leader, and don’t hesitate to ask if something is unclear. We want you to be comfortable and well-informed. Remember that when you’re working you are representing Midgardsblot and we expect you to behave in a professional manner. PS We walk fast and efficiently to get the job done, but we never run!</p>
          </div>

          <div className='left-align' id="work-clothes">
  <h2 className='norse-font'>Work Clothes</h2>
  <hr className='sep'></hr>
  <p>Wear what you like, but please dress warm, practical, and appropriate for work. The Norwegian weather is famous for being whimsical, and we strongly recommend that you wear comfortable shoes. While working, you should always wear your Crew-shirt and Crew-pass. When you’re off duty and visiting the festival as a guest, please remove your Crew-shirt and Crew-pass. If your job requires certain attire, your Team leader will inform you about this in your Team-group or by email. Don’t forget that you need to follow the EHS (HMS) guidelines regarding safety protection.</p>
  <p>For those working with rigging, as stagehands, or handling any electrical equipment, it is crucial to wear sturdy, protective footwear for your own safety. Ensure that your shoes are closed-toe and provide good support to minimize the risk of injury.</p>
</div>

          <div className='left-align' id="info-meeting">
  <h2 className='norse-font'>Info Meeting (Volunteers Only)</h2>
  <hr className='sep'></hr>
  <p><strong>Date:</strong> Tuesday, August 13th</p>
  <p><strong>Time:</strong> 17:00 (5 PM)</p>
  <p>Einherjar Crew kickoff at Borre school. Attendance is mandatory, but <strong>ONLY</strong> if your travel itinerary or work schedule allows you to be physically present. The meeting will not be streamed. More info will follow.</p>
</div>
          <div className='left-align' id="crew-shuttle">
  <h2 className='norse-font'>Extra crew shuttles</h2>
  <p><strong>13:00</strong> Midgardsblot - Gladheimr</p>
<p><strong>13:05</strong> Gladheimr - Midgardsblot</p>
<p><strong>13:10</strong> Midgardsblot - Gladheimr</p>
<p><strong>13:15</strong> Gladheimr - Midgardsblot</p>
<p><strong>13:25</strong> Midgardsblot - Gladheimr</p>
<p><strong>13:45</strong> Gladheimr - Midgardsblot</p>
<p><strong>13:50</strong> Midgardsblot - Gladheimr</p>
<p><strong>13:55</strong> Gladheimr - Midgardsblot</p>
<p><strong>00:45</strong> Midgardsblot - Utgard (<strong>00:55</strong>) - Gladheimr</p>
<p><strong>01:05</strong> Midgardsblot - Utgard (<strong>01:15</strong>) - Gladheimr - Dal Gård (<strong>01:21</strong>)</p>
<p><strong>01:30</strong> Midgardsblot - Utgard (<strong>01:35</strong>) - Gladheimr (<strong>01:40</strong>)</p>
<p><strong>01:45</strong> Midgardsblot - Utgard (<strong>01:55</strong>) - Gladheimr - Dal Gård (<strong>02:01</strong>)</p>

</div>
          <div className='left-align'  style={{ backgroundColor: 'lightyellow', fontWeight: 'bold',borderStyle: 'dotted'  }}>
           <br/>
            <h1 className='norse-font' id="gladheimr">Gladheimr Camp Guidelines & Travel Advice for Volunteers</h1>
            <hr className='sep'></hr>
            <p>Welcome to Gladheimr, the resting place for Einherjars between shifts at Midgardsblot. This camp is located at Borre Ungdomskole, a 25-minute walk from the burial grounds and 20 minutes from the bonfire at the festival camp, Utgard. Please remember, Gladheimr is not a party camp. Respect for different work schedules and cultures is essential to maintain a peaceful environment. Volunteers must be 18 years or older.</p>
            <p>Below, you'll find essential guidelines, practical information, and tips to enhance your experience at the camp.</p>

            <h3>Guidelines and Tips</h3>

            <h4>Quiet Zone</h4>
            <ul>
              <li><strong>Quiet Area</strong>: We have a secluded area, "Stillesone," located in the Gymsal, for those who prefer a quieter environment. You can request a spot during registration or upon arrival.</li>
            </ul>

            <h4>General Rules</h4>
            <ul>
              <li><strong>No Glass</strong>: Use containers and bottles made from materials other than glass.</li>
              <li><strong>No Sound Systems</strong>: Loud music and noise are only permitted in indoor hangout areas. For parties, please use the festival arena and the bonfire at the beach.</li>
              <li><strong>Cleanliness</strong>: Keep your area tidy. Use the trash bins provided, and if they are full, place the closed bag in the hallway for disposal.</li>
              <li><strong>No Open Fires</strong>: Open fires, including gas cookers, are not allowed. A communal kitchen is available daily from 12 PM - 3 PM and 6 PM - 9 PM.</li>
            </ul>

            <h4>Shower Facilities</h4>
            <ul>
              <li><strong>Location</strong>: Showers are available at Borrehallen.</li>
              <li><strong>Dates and Times</strong>: Open from Monday, August 12th to Saturday, August 28th, from 8 AM to 4 PM.</li>
            </ul>

            <h4>Packing Tips</h4>
            <ul>
              <li><strong>Essential Items</strong>: Good shoes, wool and warm clothes, raincoat, sleeping bag, shorts, long pants, sweater, T-shirts, sleeping mat/mattress (max size 90x200 cm for 1 person, 150 cm if 2), pillow, phone charger, medications, toiletries, towel, and clothing for varied weather conditions.</li>
              <li><strong>Additional Items</strong>: Sunscreen, mosquito repellent, and entertainment (books, etc.). The weather can be unpredictable, so prepare for both warm days and rain.</li>
            </ul>

            <h4>Valuables</h4>
            <ul>
              <li><strong>Locker Boxes</strong>: Available for valuables in the school. Bring a padlock and contact HQ to assign a locker. Remember, your belongings are your responsibility. For critical medications, contact the Camp Leader upon arrival for safekeeping arrangements.</li>
            </ul>

            <h4>Hangout Areas</h4>
            <ul>
              <li><strong>Indoor Hangouts</strong>: Relax, charge your phone, and enjoy simple meals here. Water boilers are available.</li>
              <li><strong>Outdoor Hangouts</strong>: Smoking is allowed only in designated areas.</li>
            </ul>

            <h4>Camp Operations</h4>
            <h5>Opening Hours</h5>
            <ul>
              <li><strong>Camp HQ</strong>:</li>
              <ul>
                <li><strong>Check-In</strong>:</li>
                <ul>
                  <li>Sunday, August 11th: 3 PM - 10 PM</li>
                  <li>Monday, August 12th - Saturday, August 17th: 8 AM - 10 PM</li>
                </ul>
                <li><strong>Check-Out</strong>:</li>
                <ul>
                  <li>Sunday, August 18th: Before 3 PM</li>
                </ul>
                <li><strong>Kitchen</strong>: Open daily from 12 PM - 3 PM and 6 PM - 9 PM.</li>
              </ul>
            </ul>

            <h4>Rules at Borre School (Gladheimr)</h4>
            <ul>
              <li><strong>Arrival</strong>: Check in at HQ and receive your room number.</li>
              <li><strong>Instructions</strong>: Follow all instructions from the Gladheimr crew.</li>
              <li><strong>Personal Responsibility</strong>: Dispose of your trash and maintain your space.</li>
              <li><strong>Prohibited Items</strong>: Glass bottles, power generators, animals, gas burners, weapons, and sharp knives are not allowed.</li>
              <li><strong>Alcohol Consumption</strong>: Only allowed in designated indoor hangout areas.</li>
              <li><strong>Music</strong>: Permitted only in designated common areas and must be quiet after 11 PM.</li>
              <li><strong>Respect</strong>: Be kind and respectful to fellow volunteers.</li>
            </ul>

            <h4>Waste Management</h4>
            <ul>
              <li><strong>Sorting Waste</strong>:</li>
              <ul>
                <li><strong>Food Waste</strong>: Includes items like coffee grounds, apple cores, small meat bones, etc. Do not include items like snuff, soil, or chewing gum.</li>
                <li><strong>Paper, Cardboard, and Cartons</strong>: Includes newspapers, cardboard boxes, etc. Do not include soiled or wet paper.</li>
                <li><strong>Glass and Metal</strong>: Recycle clean glass and metal packaging. Do not include items like porcelain or broken glass.</li>
                <li><strong>Plastic</strong>: Recycle clean plastic packaging. Do not include soiled packaging or mixed materials.</li>
                <li><strong>Residual Waste</strong>: Includes non-recyclable items like vacuum cleaner bags and chewing gum.</li>
                <li><strong>Batteries and Electronics</strong>: Deliver to designated stores or recycling stations.</li>
                <li><strong>Textiles</strong>: Donate clean and usable items. Dispose of dirty and unusable items in residual waste.</li>
                <li><strong>Medical and Hazardous Waste</strong>: Dispose of properly at pharmacies or recycling stations.</li>
              </ul>
            </ul>

            <h4>Contact Information</h4>
            <p><strong>Camp Leader Gladheimr</strong>:<br/>
            Name: Elina Volder<br/>
            Email: <a href="mailto:volcamp@midgardsblot.no">volcamp@midgardsblot.no</a><br/>
            Phone: +47 93876290</p>
            <p>For any special needs or inquiries, please reach out to Elina.</p>

            <p>We hope you have a memorable and enjoyable experience at Gladheimr Camp. Remember to respect the rules, maintain cleanliness, and be considerate to your fellow volunteers!</p>
          </div>

          <div className='left-align'  style={{ backgroundColor: 'lightyellow', fontWeight: 'bold', borderStyle: 'dotted' }}>
          <br/>
            <h1 className='norse-font' id="utgard">Utgard Camp Guidelines for Volunteers</h1>
            <hr className='sep'></hr>
            <p><strong>Welcome to Camp Utgard</strong>, a scenic camping area at the heart of the Midgardsblot festival experience. Located at Prestegårdsstranda, this picturesque campsite is nestled by the beach and surrounded by a large forest, offering a unique environment near Viking grave mounds. Just a 10-minute walk through the park brings you to the main festival site. Please adhere to the following guidelines and tips to ensure a safe and enjoyable stay.</p>

            <h3>General Information</h3>
            <ul>
              <li><strong>Camp Opening</strong>: Monday, August 12, at 15:00</li>
              <li><strong>Camp Closing</strong>: Sunday, August 18, at 14:00</li>
              <li><strong>Eligibility</strong>: Camping is available only to those that selected Camp Utgard in the forms sent out. If you opted to sleep in a tent with someone, you cannot change this.</li>
              <li><strong>Tent size</strong>: No larger than 4m x 4m</li>
            </ul>

            <h3>Guidelines and Tips</h3>

            <h4>Camp Rules</h4>
            <ul>
              <li><strong>No Glass</strong>: Glass bottles are prohibited. Please use alternatives for beverages.</li>
              <li><strong>Prohibited Items</strong>: Power generators, animals, gas burners, weapons, and sharp objects are not allowed.</li>
              <li><strong>Tent Placement</strong>: Do not set up tents on access roads or in front of emergency exits.</li>
              <li><strong>Fire Safety</strong>: No open fires, BBQs, grills, or primus stoves allowed within the camp area. Communal grills are available for cooking.</li>
              <li><strong>Noise Control</strong>: Quiet hours are between 04:00 and 08:00. Respect the quiet zone areas for those who wish to rest.</li>
              <li><strong>Cleanliness</strong>: Use provided trash bins and recycling containers. Take all trash and personal belongings with you upon departure.</li>
            </ul>

            <h4>Camping Facilities</h4>
            <ul>
              <li><strong>Bonfires and Grills</strong>: Enjoy public bonfires at the beach. Communal grills are available for preparing meals.</li>
              <li><strong>Electricity</strong>: No power outlets at the campsite. A charger station is available for phones, tablets, and laptops. Power banks cannot be charged at the station.</li>
              <li><strong>Toilets and Showers</strong>: Toilets with running water are available, closed between 22:00-08:00. Showers are accessible at Borrehallen from 08:00-16:00.</li>
              <li><strong>Security and Lost Items</strong>: The camp is secure with 24/7 staffing. Lost items may be checked for safety reasons.</li>
              <li><strong>Valuables and Primus Storage</strong>: Store valuables in the staff tent for safekeeping. Primus stoves can be stored and used outside the camp area.</li>
            </ul>

            <h4>Packing Tips</h4>
            <ul>
              <li><strong>Essential Items</strong>: Bring a tent (maximum size 4x4 meters), sleeping bag, sleeping mat, weather-appropriate clothing, toiletries, and personal items. Consider packing a raincoat, sunscreen, mosquito repellent, and entertainment (books, etc.).</li>
              <li><strong>Valuable Storage</strong>: Secure lockers are not available. Use the staff tent for storing valuables.</li>
            </ul>

            <h4>Contact Information</h4>
            <p><strong>Camp Leader Utgard</strong>:<br/>
            Name: Cecilie<br/>
            Email: <a href="mailto:camp@midgardsblot.no">camp@midgardsblot.no</a></p>

            <p>We hope you have an unforgettable experience at Camp Utgard. Enjoy the festival, respect the camp rules, and make the most of your time!</p>
          </div>
         



         
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Information;
