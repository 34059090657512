import React from 'react';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';

import informationImage from './3.jpg'; 

function PrivacyPolicy() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>PRIVACY POLICY</h1>
          </div>
        </div>
      </header>
      <main>
    
        <section className="lineup-section">
        
        <p>We here at the Midgardsblot Metal Festival take your privacy seriously and we will only use your personal data to handle your customer relationship and to deliver the services you have ordered from us. You can read more about who we are and what we do on www.midgardsblot.no
</p>
<p>All personal data are collected, stored, processed, handed out and shredded in accordance with the Personal Data Protection Act and the General Data Protection Regulation (GDPR). This Privacy Statement explains in detail how we treat personal information.
</p>

</section>
<section className="lightred-section">
  <h2>1. Who we are</h2>
  <p>Yme Drift AS with organization number 915 831 354 and business address Bogstadveien 27 B, 0355 Oslo.</p>

  <h2>2. What personal information we collect from you?</h2>
  <p>We do not basically collect personal information about visitors on our website www.midgardsblot.no. We may collect information about your use of our site if your browser allows the use of cookies.
  If you contact us, we process your personal information only to follow up your inquiry.
  You can choose to agree that we can send you our newsletter at your e-mail address about the festival.
  If you choose to sign up as a volunteer, or apply for accreditation we will save your info. But only the persons in charge of volunteers and accreditation will have access to this.</p>

  <h2>3. Why we collect personal information</h2>
  <p>The purpose of collecting personal information is to offer you the services offered on www.midgardsblot.no.</p>

  <h2>4. How your personal information is used</h2>
  <p>Your contact details, including personal information, are used to provide you with the services on our website www.midgardsblot.no and as explained in this privacy statement or in your own consent text you have read and accepted.</p>

  <h2>5. How we share your personal information with others</h2>
  <p>We do not disclose your personal information to other third parties. We may disclose personal information to Norwegian police or other public authorities if we are submitted a seizure decision, court order or other equivalent requirement for disclosure of information.</p>

  <h2>6. How long we keep your personal information</h2>
  <p>We process your personal information as long as necessary for each treatment. We will delete your personal information if you ask us for it and as soon as possible and no later than 1 year after your customer relationship with us has ended.
  Personal information relating to invoices and payment is retained for 10 years, as required by Norwegian accounting legislation.</p>

  <h2>7. Your rights</h2>
  <p>You have rights through GDPR to know your personal information registered in our systems. You may access and update your personal information at any time by sending us an email to info@midgardsblot.no to update your information.
  You may withdraw your consent at any time given to us. You can do this by sending us an e-mail to info@midgardsblot.no.</p>

  <h2>8. Cookie Policy</h2>
  <p>We use cookies to optimize your site and your user experience. A cookie is a cookie stored temporarily or persistently as a small file on your computer. We use third party cookies to provide us with information about what visitors are interested in on our site and to measure traffic on our site. The purpose of this is to improve web pages and customize our offers, and we will not use any personal information from cookies to something else.
  If you do not wish to accept our use of cookies, you can make changes to the browser yourself, so your computer will automatically reject cookies. We note that you can not use all our functionality on our site.</p>
</section>
      </main>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
