import React from 'react';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import './../Information.css';
import informationImage from './wristband.jpg'; 


function Wristband() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>TICKET TO WRISTBAND</h1>
          </div>
        </div>
      </header>
      <main>
        <section className="lineup-section">
          <h1 className='norse-font'>TICKET TO WRISTBAND</h1>
          <p>Located next to the Midgard Vikingsenter museum.</p>

          <p><strong>TICKETS to WRISTBANDS</strong></p>
          <p>Mon-Tues 12.00 - 18.00</p>
          <p>Wed-Sat 10.00 - 22.00 </p>
          <br/>
          <p><strong>ACCREDITATION</strong></p>
          <p>Tuesday 10.00 - 20.00</p>
          <p>Wed-Sat 10.00 - 22.00</p>
        </section>
        
      </main>
      <section className='red-section'>
      <h3 >TICKET AND ACCREDITATION EXCHANGE</h3>

      <div className="information-grid2">
          <div>
          <h3 className='norse-font'>Entry Requirements and IDs</h3>
          <hr className='sep'></hr>
        
    <ul>
        <li><strong>Valid ID Required:</strong> All festival guests must bring a valid photo ID. Guests aged 18 and over will receive a wristband for alcohol purchase. Without a valid ID, you cannot buy alcoholic beverages.</li>
        <li><strong>Age Limits:</strong> Camp Utgard has a strict 18-year age limit. Valid ID is required for check-in.</li>
        <li><strong>Fake IDs:</strong> The use of false credentials will be prosecuted, police will be summoned, and access will be denied.</li>
        <li><strong>Children:</strong> Children under 12 get in free with an adult ticket holder. Children 12 and over need their own ticket. All children under 16 must be accompanied by a sober adult over 25, who must register as the responsible adult with contact info upon entry.</li>
        <li><strong>ID:</strong> Keep your ID ready for ticket to wristbad exchange</li>
    </ul>
          </div>
          <div>
          <h3 className='norse-font'>Ticketing and Access</h3>
          <hr className='sep'></hr>
    <ul>
        <li><strong>Ticket Scanning:</strong> Ensure your tickets can be scanned on your phone. If your screen is broken, bring a printed ticket. There is no public Wi-Fi at Midgardsblot, so have tickets ready on your phone beforehand.</li>
        <li><strong>Separate Lines:</strong> There are separate lines for ticket holders and accreditation. Arrive early to avoid delays before your favorite artist’s performance.</li>
        <li><strong>Camp Ticket Scanning:</strong> Camp Utgard and Folkvangr can scan tickets before the main ticket exchange opens. All other tickets must be exchanged at the ticket exchange.</li>
    </ul>
          </div>
        </div>


      </section>
      <Footer />
    </div>
  );
}

export default Wristband;
