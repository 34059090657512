import React, { useEffect } from 'react';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import './Home.css';
import poster from './poster2024.jpg';
import map from './map_audience_exit.png';

function Home() {
  useEffect(() => {
    const countdown = () => {
      const targetDate = new Date('2025-08-13T14:00:00+02:00').getTime();
      const now = new Date().getTime();
      const distance = targetDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      document.getElementById('countdown').innerHTML =
        days + 'D ' + hours + 'H ' + minutes + 'M ' + seconds + 'S ';

      if (distance < 0) {
        clearInterval(intervalId);
        document.getElementById('countdown').innerHTML = 'The event has started!';
      }
    };

    const intervalId = setInterval(countdown, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="Home">
      <Navbar />
      <header className="Home-header">
        <div className="hero-section">
          <video autoPlay muted loop className="hero-video">
            <source src="https://www.midgardsblot.no/img/video/homepage.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="hero-overlay">
            
            <div className="countdown-timer">
              <img alt="border" src="https://midgardsblot.no/resources/assets/border.png"/>
              <h2 className='norse-font' id="countdown"> </h2>
              <img alt="border" src="https://midgardsblot.no/resources/assets/border.png"/>
              
            </div>
            <p className='norse-font'>Join us for mysteries of the past, melodies of the present, and dive into the heart of Midgardblot with us!</p>
            <p>Early bird tickets in sale now!</p>
            <button className='norse-font-bold cta-button'>GET YOUR TICKETS!</button>
          </div>
        </div>
      </header>
      <main>
        <section className="lineup-section">
          <div className="lineup-grid">
           
            <div>
              <h3 className="norse-font">PROGRAM</h3>
              <hr className="sep"></hr>
              <p>Click here to see the full festivl program with all times!</p>
              <a href="/schedule" className="read-more-button">See program</a>
            </div>
            <div>
              <img style={{ width: '100%' }} src={poster} alt="Festival Lineup"></img>
           
            </div>
            <div>
              <h3 className="norse-font">MIDGARD WALKS & TALKS</h3>
              <hr className="sep"></hr>
              <p>Wish to expand your knowledge? Don't miss out on Midgard Talks & Walks!</p>
              <a href="/program/midgard" className="read-more-button">Read More</a>
            </div>
          </div>
        </section>
        <section className='red-section'>
          <img src={map} style={{ width: '80%' }} alt="festival-map"></img>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
