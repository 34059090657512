import React from 'react';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import './../Information.css';

import informationImage from './train.jpg'; 

function Train() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>TRAIN</h1>
          </div>
        </div>
      </header>
      <main>
    
        <section className="lightred-section">
        <h1 className='norse-font'>FROM OSLO AIRPORT GARDERMOEN</h1>
        <hr className='sep'></hr>
        <p>
          When arriving in Norway, fly into Oslo Gardermoen Airport. From there, take the regional train R11 (Skien) from the airport to Skoppum for Horten or the festival, or to Tønsberg if staying there. Trains leave hourly:
        </p>
         
          <strong>Mon-Fri:</strong> 05:13 - 23:13
          <br/><strong>Sat:</strong> 06:13 - 17:13, then 19:13, 21:13, 23:13
        
        <p>
          The journey to Skoppum takes 1 hour 20 minutes. Find updated timetables and buy tickets here:
        </p>
<a href="https://www.vy.no/en" rel="noreferrer"  className="read-more-button">VY.NO</a><br/> <br/> 

</section>
<section className="lightred-section">
<h1 className='norse-font'>FROM DOWNTOWN OSLO</h1>
<hr className='sep'></hr>
<p>
          If you are already in Oslo and traveling to the festival from there, simply take a train from Oslo S (Oslo central station). You have 2 options here, depending on which departure time suits you better:
        </p>
        <h3>Option 1:</h3>
        <p>
          Take a train from Oslo S to Skoppum station (train line “R11 Skien”). It only takes 1 hour and an R11 train leaves from Oslo S every hour. You can find the updated timetables for trains and buy tickets online <a href="https://www.vy.no/en" target="_blank" rel="noopener noreferrer">here</a>.
        </p>
        <p>
          Once you get to Skoppum station, you can take a Midgardsblot Festival Shuttle to the festival site or to Thon Hotel Horten. The shuttle will leave every hour and prices and more details will follow soon.
        </p>
        <h3>Option 2:</h3>
        <p>
          Take a train from Oslo S to Moss station (train line “R20 Halden” or “L21 Moss”). It only takes 45 minutes, and a train leaves this direction from Oslo S about every half hour. You can find the updated timetables for trains and buy tickets online <a href="https://www.vy.no/en" target="_blank" rel="noopener noreferrer">here</a>.
        </p>
        <p>
          Once you get to Moss station, it is a very short walk from the station to the ferry departure. As a pedestrian (without car), you can use the ferry for free. The name of the ferry is Bastø Fosen and you can find the updated schedule on their website (in English) <a href="https://basto-fosen.no/schedules/" target="_blank" rel="noopener noreferrer">here</a>. The ferry ride takes only 20 minutes and there is a café on board the ferry. The ferry arrives in Horten, immediately across from Thon Hotel Horten. From there, the Midgardsblot Festival Shuttle takes you straight to the festival site.
        </p>
        <a href="https://www.vy.no/en" rel="noreferrer"  className="read-more-button">VY.NO</a><br/> <br/> 

        </section>
       
      
      </main>
      <Footer />
    </div>
  );
}

export default Train;
