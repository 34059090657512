import React from 'react';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';

import informationImage from './6.jpg'; 

function Contract() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>VOLUNTEER CONTRACT 2024</h1>
          </div>
        </div>
      </header>
      <main>
    
        <section className="lineup-section">
        
        <h2>Your responsibilities:</h2>

</section>
<section className="lightred-section">

      <div>
        
        <ul>
          <li>Be precise and meet as planned. Notify your Team Leader immediately if you’re running late.</li>
          <li>Be available by phone and email so your Team Leader and Crew Manager can contact you.</li>
          <li>Do not leave the area without notifying your Team Leader. Only your Team Leader and Crew Manager can release you from your shift.</li>
          <li>Alcohol and drug consumption during your shifts is strictly prohibited. Do not show up to your shifts intoxicated.</li>
          <li>Wear your crew merchandise when you are working, not when you are off duty. Remove your crew T-shirt and crew pass before consuming alcohol. But feel free to use the t-shirt whenever you want after the festival.</li>
          <li>Personal contact details of other crew members are not to be exploited or misused in any way.</li>
          <li>Harassing artists, staff members, festival guests and/or fellow volunteers is not tolerated.</li>
          <li>Always follow the EHS compliance (HMS) and security guidelines given by a person in charge. Use earplugs when exposed to high decibels and wear protective gear when loading and rigging.</li>
          <li>Please refrain from photographing and/or videofilming artist performances while you’re working.</li>
          <li>Respect the privacy of the artists. Do not photograph or ask for autographs. It is strictly prohibited to film and photograph backstage/green room areas, or share sensitive information acquired from working backstage/with artists. This includes riders and setlists.</li>
          <li>Do not speak to the press on behalf of the festival.</li>
        </ul>
        <h2>If you should not follow your responsibilities:</h2>
        <ul>
          <li>Your festival wristband/accreditation will be withdrawn.</li>
          <li>You will be asked to leave the premises with immediate effect.</li>
          <li>If you fail to show up to your agreed shifts, we will require full payment for your festival accreditation. If you only show up to one of your confirmed shifts, we will require 50% of the festival pass value.</li>
        </ul>
        <h2>In return you will receive:</h2>
        <ul>
          <li>Crew T-shirt</li>
          <li>Festival entrance the days you are not working</li>
          <li>Meals each day when working</li>
          <li>Kickoff and closing party</li>
          <li>Work Certificate</li>
          <li>A safe working environment</li>
        </ul>
        <p>
          <em>* This contract is binding. By clicking consent you confirm that the contract is read and that you have agreed to and understood the terms for working as a volunteer at Midgardsblot Metal Festival.</em>
        </p>
      </div>
        </section>

      </main>
      <Footer />
    </div>
  );
}

export default Contract;
