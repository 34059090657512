import React from 'react';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import './../Information.css';


import informationImage from './air.jpg'; 



function Air() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>OSLO AIRPORT GARDERMOEN</h1>
          </div>
        </div>
      </header>
      <main>
    
        <section className="lineup-section">
        
         <p>When traveling to Midgardsblot by Air, the easiest point of access will be Oslo Airport Gardermoen</p>

         <p>More information can be found on Gardermoens website here:</p>
         <a href="https://avinor.no/en/airport/oslo-airport/" rel="noreferrer"  className="read-more-button">GARDERMOEN</a><br/> <br/> 

        </section>
       
      
      </main>
      <Footer />
    </div>
  );
}

export default Air;
