import React from 'react';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import informationImage from './program2.jpg'; 



function Program() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>PROGRAM</h1>
          </div>
        </div>
      </header>
      <main>
        <section className="lineup-section">
          
        <div className="lineup-grid">
         
            <div>
              <h3 className='norse-font'>FULL LINEUP</h3>
              <hr className='sep'></hr>
              <p>See the full lineup!</p>
              <a href="/lineup" className="read-more-button">Read More</a> 
            </div>
            <div>
              <h3 className='norse-font'>FESTIVAL PROGRAM</h3>
              <hr className='sep'></hr>
              <p>Click to see the full festival program here</p>
              <a href="/schedule" className="read-more-button">Read More</a> 
            </div>
            <div>
            
              <h3 className='norse-font'>LECTURES & OTHER ACTIVITIES</h3>
              <hr className='sep'></hr>
              <p>Here you can read more about lectures, workshops and tastings held during the festival</p>
              <a href="/program/activities" className="read-more-button">Read More</a> 
            </div>
            
          </div>
         
        </section>
        <section className="info-section" style={{ marginTop: '0em' }}>
        <div className="info-grid">
        <div>
            
            
            <h3 className='norse-font'>GRIMFROST</h3>
            <hr className='sep'></hr>
            <p>Grimfrost is set to make a grand appearance at Midgardsblot</p>
            <a href="/program/grimfrost" className="read-more-button">Read More</a> 
          </div>
        <div>
              <h3 className='norse-font'>SAGA OSEBERG</h3>
              <hr className='sep'></hr>
              <p>Sail with the legendary Saga Oseberg replica</p>
              <a target="_BLANK" rel="noreferrer" href="https://osebergvikingarv.bilberry.app/web/products/5/calendar?lang=en" className="read-more-button">Read More</a> 
            </div>
            <div>
            
            <h3 className='norse-font'>MIDGARD TALKS & WALKS</h3>
            <hr className='sep'></hr>
            <p>Wish to expand your knowledge? Dont miss out on Midgard Talks & Walks!</p>
            <a href="/program/midgard" className="read-more-button">Read More</a> 
          </div>
         
          <div>
            
            
              <h3 className='norse-font'>BADSTUVOGNA</h3>
              <hr className='sep'></hr>
              <p>Badstuvogna booking</p>
              <a href="https://badstuvogna.no/booking/" className="read-more-button">Read More</a> 
            </div>
        
        </div>
        </section>
       
        
        
      </main>
      <Footer />
    </div>
  );
}

export default Program;
