import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './home/Home.js'; 
import Volunteer from './volunteer/Volunteer.js';
import VolunteerInformation from './volunteer/Information.js'; 
import HMS from './volunteer/HMS.js'; 
import Folkvangr from './folkvangr/Folkvangr.js'; 
import Program from './program/Program.js'; 
import Schedule from './program/schedule/Schedule.js'; 
import Activities from './program/activities/Activities.js'; 
import PrivacyPolicy from './privacypolicy/PrivacyPolicy.js'; 
import Contract from './volunteer/Contract.js'; 
import Bands from './program/bands/Bands.js';
import Grimfrost from './program/grimfrost/Grimfrost.js';
import Lineup from './program/lineup/Lineup.js'; 
import Midgard from './program/midgard/Midgard.js'; 
import Information from './information/Information.js'; 
import Shuttle from './information/shuttle/Shuttle.js'; 
import Payment from './information/payment/Payment.js'; 
import Parking from './information/parking/Parking.js'; 
import Train from './information/train/Train.js'; 
import Air from './information/air/Air.js'; 
import Car from './information/car/Car.js'; 
import Bus from './information/bus/Bus.js'; 
import Ticket from './information/ticket/Ticket.js'; 
import Children from './information/children/Children.js'; 
import Availability from './information/availability/Availability.js'; 
import FestivalArea from './information/FestivalArea/FestivalArea.js'; 
import Wristband from './information/wristband/Wristband.js'; 
import Faq from './information/faq/Faq.js'; 
import Camp from './information/camp/Camp.js'; 
import Accomodation from './information/accomodation/Accomodation.js'; 

import './styles/fonts.css';
import './App.css';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} /> 
        <Route path="/volunteer" element={<Volunteer />} />
        <Route path="/volunteer/information" element={<VolunteerInformation />} />
        <Route path="/hms" element={<HMS />} />
        <Route path="/folkvangr" element={<Folkvangr />} />
        <Route path="/program" element={<Program />} />
        <Route path="/lineup" element={<Lineup />} />
        <Route path="/program/midgard" element={<Midgard />} />
        <Route path="/program/activities" element={<Activities />} />
        <Route path="/program/grimfrost" element={<Grimfrost />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/contract" element={<Contract />} />
        <Route path="/program/bands/:bandname" element={<Bands />} />
        <Route path="/information" element={<Information />} />
        <Route path="/information/shuttle" element={<Shuttle />} />
        <Route path="/information/payment" element={<Payment />} />
        <Route path="/information/parking" element={<Parking />} />
        <Route path="/information/train" element={<Train />} />
        <Route path="/information/air" element={<Air />} />
        <Route path="/information/car" element={<Car />} />
        <Route path="/information/bus" element={<Bus />} />
        <Route path="/information/ticket" element={<Ticket />} />
        <Route path="/information/children" element={<Children />} />
        <Route path="/information/availability" element={<Availability />} />
        <Route path="/information/FestivalArea" element={<FestivalArea />} />
        <Route path="/information/wristband" element={<Wristband />} />
        <Route path="/information/faq" element={<Faq />} />
        <Route path="/information/camp" element={<Camp />} />
        <Route path="/information/accomodation" element={<Accomodation />} />
      </Routes>
    </Router>
  );
};

export default App;
