import React from 'react';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import './../Information.css';

import informationImage from './faq.jpg'; 



function Faq() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>FAQ</h1>
          </div>
        </div>
      </header>
      <main>
    
        <section className="lineup-section">
        
        <p>Here you can find our most frequently asked questions, we also suggest joining our community group on facebook </p>
        <a href="https://www.facebook.com/groups/midgardsblot" rel="noreferrer" target="_BLANK" className="read-more-button">Midgardsblot Official Community</a> 
       
</section>
<section className="info-section">
<div className="info-grid">
<div>
    <h3 className='norse-font'>What is the age limit at the festival?</h3>
    <hr className='sep'></hr>
    <p style={{ fontSize: '0.8em' }}>Kids are welcome at Midgardsblot! Read more here:
    </p>
    <a href="/information/children" className="read-more-button">Read More</a> 
</div>

<div>
    <h3 className='norse-font'>What is the age limit to stay at the camp?</h3>
    <hr className='sep'></hr>
    <p style={{ fontSize: '0.8em' }}>You have to be 18 years or older to stay at the camp. No exceptions.</p>
</div>

<div>
    <h3 className='norse-font'>Is it ok that there is another name on my ticket?</h3>
    <hr className='sep'></hr>
    <p style={{ fontSize: '0.8em' }}>Yes, that is perfectly fine.</p>
</div>

<div>
    <h3 className='norse-font'>Can I buy day tickets?</h3>
    <hr className='sep'></hr>
    <p style={{ fontSize: '0.8em' }}>You can buy day tickets for all days.</p>
</div>

<div>
    <h3 className='norse-font'>I am disabled, can I bring a companion?</h3>
    <hr className='sep'></hr>
    <p style={{ fontSize: '0.8em' }}>If you have a disability and you have a companion with a valid companion ID, they will be allowed to enter for free.</p>
    <a href="/information/availability" className="read-more-button">Read More</a> 
</div>

<div>
    <h3 className='norse-font'>I’ve bought tickets for multiple people, how can I give them their tickets?</h3>
    <hr className='sep'></hr>
    <p style={{ fontSize: '0.8em' }}>Ticketmaster has a ticket transfer function that allows you to transfer tickets to another person. To read more about it go to: <a href="https://help.ticketmaster.no/hc/en-us/articles/6267011344785-Ticket-Transfer">Ticket Transfer</a></p>
</div>

<div>
    <h3 className='norse-font'>Which tickets do I need to stay at the camp?</h3>
    <hr className='sep'></hr>
    <p style={{ fontSize: '0.8em' }}>If you wish to stay at the camp you will need a tent ticket and a camping ticket. The tent ticket is for the spot you put up your tent, and the camping ticket is for access to the camp. If you are two people staying in the same tent you would need 2 camping tickets and one tent ticket. IMPORTANT: You need a festival ticket, weekend pass, or day pass to stay at the camp.</p>
</div>

<div>
    <h3 className='norse-font'>Do you get a tent with the tent ticket?</h3>
    <hr className='sep'></hr>
    <p style={{ fontSize: '0.8em' }}>No, we do not supply you with tents. This ticket is only for the spot where you put up your own tent.</p>
</div>

<div>
    <h3 className='norse-font'>What is the difference between the two camping tickets?</h3>
    <hr className='sep'></hr>
    <p style={{ fontSize: '0.8em' }}>Camp ticket - All days: Gives you access to the camp from Monday to Sunday. Camp ticket - 4 days: Gives you access to the camp from Wednesday to Sunday.</p>
</div>

<div>
    <h3 className='norse-font'>How do I get to the festival?</h3>
    <hr className='sep'></hr>
    <p style={{ fontSize: '0.8em' }}>Midgardsblot takes place next to the Viking Age grave mounds in Borre, near Horten, in Vestfold County, Norway. It is right by the beach (Oslo Fjord) and near the Midgard Vikingsenter Museum.
        ADDRESS: Birkelyveien 9, 3184 Borre, Norway. Traveling to the festival site is quick and easy with public transport.
        More info here
    </p>
</div>

<div>
    <h3 className='norse-font'>Will there be parking at the festival?</h3>
    <hr className='sep'></hr>
    <p style={{ fontSize: '0.8em' }}>Yes. More info TBA...</p>
</div>
      </div>
</section>

      </main>
      <Footer />
    </div>
    
  );
}

export default Faq;
