import React, { useState, useEffect } from 'react';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import informationImage from './6.jpg';

function HMS() {
  const [currentSection, setCurrentSection] = useState(0);
  const [inviteUrl, setInviteUrl] = useState('');

  useEffect(() => {
    // Extract the invite URL from the query string
    const queryString = window.location.search;
    if (queryString.startsWith('?')) {
      const url = queryString.substring(1);
      if (url) {
        setInviteUrl(url);
      }
    }
  }, []);

  const sections = [
    {
      title: '1',
      content: `Midgardsblot should be a safe, inclusive, and apolitical arena for everyone, regardless of age, gender, orientation, or skin color.<br/>
      Show that you care. Take responsibility and be a positive role model.<br/>
      Remember that you represent the festival.`,
    },
    {
      title: '2',
      content: `Midgardsblot aims to actively prevent injuries, avoid accidents, and reduce unwanted incidents.<br/>
      By focusing on waste sorting, recycling, and the environment, we aim to reduce the festival's ecological footprint.`,
    },
    {
      title: '3',
      content: `Familiarize yourself with your tasks and follow the festival's standard procedures.<br/>
      Ask your immediate supervisor if something is unclear or if you are unsure about anything. Remember, it's better to ask one time too many.<br/>
      We should work efficiently, but never at the expense of safety.`,
    },
    {
      title: '4',
      content: `Report to your immediate supervisor or safety representative if you observe any violations of safety rules or anything that could endanger life and health.<br/>
      If you hear about unpleasant incidents, inform your supervisor as soon as possible.`,
    },
    {
      title: '5',
      content: `Leaders are responsible for ensuring that all volunteers and employees receive the necessary training and knowledge about the work they are to perform, and that their team follows the festival's guidelines.<br/>
      A good working environment and safety at the workplace must be maintained. This includes food, drink, and necessary breaks.<br/>
      Any deviations should be immediately reported to the safety representative.`,
    },
    {
      title: '6',
      content: `During setup and dismantling, everyone must use appropriate protective equipment for their area. This may include helmets, safety shoes, safety glasses, gloves, and hearing protection.<br/>
      The nearest supervisor is responsible for ensuring that the necessary protective equipment is available at all times.`,
    },
    {
      title: '7',
      content: `All deviations are reported here: <a href="https://forms.gle/AJsvWuTFaAcxmBaXA" target="_blank" rel="noopener noreferrer">https://forms.gle/AJsvWuTFaAcxmBaXA</a><br/>
      This includes unwanted incidents, errors and deficiencies, accidents and near-misses, as well as injuries to people and equipment.`,
    },
    {
      title: '8',
      content: `ASSEMBLY POINTS<br/><img src="https://midgardsblot.no/resources/2024/volunteer/HMS-MAP.png" style="width:80%;">`,
    },
    {
      title: '9',
      content: `You must be sober while on duty. It is not allowed to consume alcohol while working.<br/>
      Remember to wait at least 12 hours before operating vehicles and machinery.`,
    },
    {
      title: '10',
      content: `Plan your tasks well. Consider whether you have the equipment and personnel you need to do the job safely.<br/>
      Are others affected by the work you are going to do? Remember to inform the right people.<br/>
      Follow the routines, don't take shortcuts.`,
    },
    {
      title: '11',
      content: `Always assess whether there is a risk involved in the job to be done. Can the risk be eliminated? Can the risk be reduced? Can you protect yourself from the risk?<br/>
      Contact SECURITY if you need assistance.`,
    },
    {
      title: '12',
      content: `Be aware of forklifts, cars, and other vehicles on the site.<br/>
      Remember that drivers of large vehicles have limited visibility.`,
    },
    {
      title: '13',
      content: `Keep a low speed on the site when operating vehicles.<br/>
      Secure the load before transport.<br/>
      Always look behind before reversing.`,
    },
    {
      title: '14',
      content: `When working at heights (over 2 meters), tools and equipment must be secured so they do not fall.<br/>
      The area below must be cordoned off.<br/>
      Use of lifts and cranes requires documented training.`,
    },
    {
      title: '15',
      content: `Power networks and electrical cabinets should only be handled by qualified personnel.<br/>
      Electrical equipment must not get wet. Treat all wires and cables with the utmost care.`,
    },
    {
      title: '16',
      content: `We have our own guidelines for measures to follow when working.<br/>
      This includes waste sorting, food waste, and protection of cultural heritage.`,
    },
    {
      title: '17',
      content: `A good working environment contributes to a safe and positive workplace.<br/>
      Think about how you can make a positive impact and contribute to good communication.<br/>
      Be proactive. Ensure good communication with others.`,
    },
    {
      title: '18',
      content: `To report safety deviations, injuries, and unwanted incidents, use this form: <a href="https://forms.gle/AJsvWuTFaAcxmBaXA" target="_blank" rel="noopener noreferrer">https://forms.gle/AJsvWuTFaAcxmBaXA</a>`,
    },
    {
      title: '19',
      content: `Important phone numbers:<br/>
      <strong>Security/Control Center:</strong> +47 986 13 617 <br/>
      <strong>Medic on duty:</strong> RED CROSS TENT<br/>
      <strong>Safety representative:</strong> +47 99 00 10 13<br/>
      <img src="https://midgardsblot.no/resources/2024/volunteer/nodnummer.jpg" style="width:50%;">`,
    },
    {
      title: '20',
      content: `HMS Organization:<br/>
      <strong>General Manager:</strong> Runa Strindin<br/>
      <strong>Safety Manager:</strong> Georg Genius<br/>
      <strong>HMS Responsible:</strong> Maikki Fonneløp<br/>
      <strong>Safety Representative:</strong> Roar Stabell`,
    },
    {
      title: 'Finished',
      content: `To complete and sign the course, please press the button below. We recommend taking a screenshot of this page as proof of completion, in case the system does not register your progress. Remember, you must complete the course to receive your accreditation.<br/><br/>
      ${inviteUrl ? `<a class="cta-button" href="${inviteUrl}" target="_blank" rel="noopener noreferrer">Complete course</a>` : ''}`,
    },
  ];

  const nextSection = () => {
    setCurrentSection((prevSection) => (prevSection + 1) % sections.length);
  };

  const previousSection = () => {
    setCurrentSection((prevSection) => (prevSection - 1 + sections.length) % sections.length);
  };

  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>EINHERJAR GUIDE 2024</h1>
          </div>
        </div>
      </header>
      <main>
        <section className="lineup-section">
          <p></p>
        </section>
        <section className="white-section">
          <div>
            <h2 className='norse-font'>{sections[currentSection].title}</h2>
            <p dangerouslySetInnerHTML={{ __html: sections[currentSection].content }} />
          </div>
          <div>
            {currentSection > 0 && <button onClick={previousSection}>Back</button>}
            {currentSection < sections.length - 1 && <button onClick={nextSection}>Next</button>}
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default HMS;
