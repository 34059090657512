import React from 'react';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import informationImage2 from './folkvangr1.jpg'; 
import informationImage from './volunteer.jpg'; 
function Folkvangr() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage2} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>Folkvangr</h1>
          </div>
        </div>
      </header>
      <main>
        <section className="lineup-section">
          
        <p>
        <b >Opening hours 14:00-22:00</b><br/><br/>
          <i>
    Fólkvangr er inn níundi,<br/>
en þar Freyja ræðr<br/>
sessa kostum í sal;<br/>
hálfan val<br/>
hún kýss hverjan dag,<br/>
en hálfan Óðinn á.<br/>

    
</i></p>
         
        </section>
        <section className="red-section">
    
          <h3 className='norse-font'>FOLKVANGR</h3>
          <hr className='sep'></hr>
         <p>

Fólkvangr was the goddess Freyja's domain, where half of those that died in combat went upon death, while the other half went to Odin in Valhalla. Freyja got first pick, and favoured the more honourable and less bloodthirsty warriors. In Midgardsblot's Fólkvangr you’ll experience the atmosphere of the Viking age, meet up with our Vikings and learn about trade.</p>
<p>
And of course, there is no viking village without merchants so here you’ll also find handcrafted Viking souvenirs, clothing and jewelry.
</p>
          
        </section>
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
          <h1 className='norse-font'>Reenactors Pass</h1>
          </div>
        </div> 
        <section className="lineup-section">
        <h3 className='norse-font'>Fólkvangr Viking Village - Reenactors Pass
</h3>
        <p>
        The Viking village is a joint effort between the reenactors and the festival. Together we give “the civilians” a whole new experience, in this mix of history and music. We, the festival, take care of infrastructure, safety, regulations and routines – you will be your beautiful selves. Either you are just present, with your tent and your historical clothes, or you also add your handcraft/skills to your presence. Either way, both our jobs is to make this experience a good one – for all. With this great opportunity comes great responsibility. If the camp coordinators find that you to violate this missions safety or positivity, you will lose your right to stay in the camp.

</p>
<a href="https://form.arkon.no/?id=10032&key=3oWR73" target="_BLANK" rel="noreferrer" className="read-more-button">Application</a> 
        </section>
        <section className="red-section">

       
       
    <h3 className='norse-font'>Folkvangr</h3>
    <hr className='sep'></hr>
    <p>Fólkvangr is situated close to Gildehallen, in a field by the main festival area. The same general rules apply for the ordinary festival camp and Fólkvangr, but with a few changes/additions, that will be addressed in our list of camp rules.</p>

<p>The Reenactors Pass is only valid for reenactors that camp and dress historically (viking/ medieval, contemporary cultures to or earlier periods than the viking age) and includes the full three days festivalpass to Midgardsblot and camping in the Fólkvangr Viking Village. You can also sell your historical products/craft during the festival, but you must then buy the "merchant pass".</p>

<p>For questions regarding folkvangr, read the information on this page and join our facebook group: <a href="https://www.facebook.com/groups/114864089196124/">Midgardsblot Viking Village</a> first.</p>

<p>If your question is not addressed there, email us at: <a href="mailto:viking@midgardsblot.no">viking@midgardsblot.no</a></p>



    <h3 className='norse-font'>INFORMATION AND RULES</h3>
    <hr className='sep'></hr>
    <p>Check-in and setting up camp is available from Monday 12 10 Am August, to Wednesday 14, 12 00 am August at the latest. it will not be possible to be driving your gear to Fólkvang after Wednesday as the area will be blocked from entering with cars. Get your wristbands and the info tent in Folkvangr (first one on the right).</p>
            <p>All cars must be out of Folkvangr by noon (12:00) Wednesday.</p>
            <p>The fighters will reside in Fólkvang. This area needs to be respected, i.e. guests are prohibited from touching or trying out equipment without permission from the owner(s).</p>
            <p><b>Weapons are forbidden</b> – even blunt ones. Only Midgardsblot’s hired fighters are allowed any sort of weapons.</p>
            <p><b>IMPORTANT:</b> We understand the need for tools that can unfortunately be abused as weapons, but you bring them at your own peril, and they cannot be taken outside of Fólkvangr, by you or anyone else. Any tools that can be used as weapons must be hidden in tent and/or out of view when not in use.</p>
            <p>Listed below are some general rules. When you apply, get your ticket link and purchase your ticket – you will receive a more substantial set of rules. These MUST be read in order for you to get through registration when arriving at camp. If the rules you receive with your tickets differ from the ones on this page, it will be because situation has changed.</p>
            <p>There will be a Folkvanger camp meeting wedsnday at 12, one from each group is required to attend.</p>



    <h3 className='norse-font'>CAMPFIRES</h3>
    <hr className='sep'></hr>
    <ul>
                <li>There will be a designated area for a bigger campfire to sit around at night. Campfires by your tent shall be placed 2m/7 feet from nearest tent.</li>
                <li>When not used for cooking, we would like it to not be fed – let it burn out or put it out.</li>
                <li>No campfire should be left unattended. If you leave your camp, put it out.</li>
                <li>Use a fire pan that is at least 15 cm (6′′) above the ground.</li>
                <li>Firewood will be provided for you, but try not to take more than you will need each day.</li>
                <li>Ashes must be completely extinguished before dumping them safely in a designated area.</li>
                <li>All tents must have a fire extinguisher placed to the right on the inside of the tent entrance.</li>
                <li>The night guards are to be respected, if they tell you to put out a fire, do it.</li>
            </ul>

    <h3 className='norse-font'>FOOD AND DRINKS</h3>
    <hr className='sep'></hr>
    <ul>
                <li>From 1100-1300 you can exit and enter the camp through the emergency fence exit for shopping etc. Please plan your shopping accordingly. there will be no other means to bring food or drink into the camp.</li>
                <li>The festival area will have bars which are open until after midnight, and is therefore subject to alcohol restrictions (by Norwegian law) during opening hours. Fólkvangr is not affected by this, but please respect the guidelines stated in the general festival rules, and pay attention to the festival staff. It is not allowed to bring alcohol from Folkvangr into the festival.</li>
            </ul>

    <h3 className='norse-font'>DRESSCODE AND REENACTORS PASS</h3>
    <hr className='sep'></hr>
    <ul>
                <li>Holders of the Reenactors Festivalpass are obligated to camp and dress historically (viking/ medieval). We do not adhere to "plastic alarms", but we do appreciate that you pick up after yourself, and use the waste containers provided. Be a clean viking!</li>
                <li>Fólkvangr will be open to the regular audience for a limited time each day, from 1400-2000, during the festival.</li>
                <li>You will be given a designated area for setting up your tent(s) upon arrival, contact the festival coordinators in the Folkvanger info tent.</li>
                <li>The times for when you can register and move in will be provided in the welcome mail.</li>
                <li>If you need to sell your ticket(s), you will have to do a refund with Ticketmaster- our ticket provider.</li>
                <li>If there is any changes or new information, we will send it by email to the registered email address. The person who registers, should forward any information to the other registered participants in the party.</li>
            </ul>

            <b>By registering, you agree to full responsibility regarding this information and rules.</b>


        
          
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Folkvangr;
