import React from 'react';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import './../Information.css';

import informationImage from './accomodation.jpg'; 



function Accomodation() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>OTHER ACCOMODATION OPTIONS</h1>
          </div>
        </div>
      </header>
      <main>
        <section className="lineup-section">
          <h1 className='norse-font'>Accommodation</h1>
          
        </section>
        <section className="red-section" >
      
        <div className="information-grid2">
          <div>
          <h3 className='norse-font'>Hotel</h3>
          <hr className='sep'></hr>
          <p>Some hotels in Tønsberg still have beds available. Staying in Tønsberg is a great option, as it is close to the festival site and can be easily reached by bus.</p>

<h3>Hotell Klubben</h3>
<p><a href="http://www.hotelklubben.no">www.hotelklubben.no</a></p>

<h3>Tønsberg Vandrerhjem</h3>
<p><a href="http://tonsbergvandrerhjem.no/">tonsbergvandrerhjem.no</a><br/>
Available rooms: 2, 4, and 6-bedrooms</p>

<h3>Willhelmsen House</h3>
<p><a href="http://www.wh.no">www.wh.no</a></p>
          </div>
          <div>
          <h3 className='norse-font'>Campervan</h3>
          <hr className='sep'></hr>
<p>If you wish to come in a campervan, you can book a camper parking space at Dal Gård Bobil Camp. The price per night for a campervan is 350 NOK and includes:</p>
<ul>
    <li>Shared, gender-specific toilets and showers</li>
    <li>Power connection</li>
    <li>Internet</li>
    <li>24-hour security</li>
    <li>Municipal taxes</li>
    <li>Motorhome emptying station</li>
</ul>
<p>The campground is located in Skoppum, an 8-minute drive from the festival site.</p>
<p>Address: Solerødveien 86, 3185 Skoppum</p>
<p>For more information, visit the <a href="https://www.dalgjestegaard.no/nn/bobilplass">Dal Gård Camp website</a> (in Norwegian). You can also reach them via email at <a href="mailto:resepsjon@dalgjestegaard.no">resepsjon@dalgjestegaard.no</a> or phone at +47 408 408 51.</p>

          </div>
          </div>
          </section>



      </main>
      <Footer />
    </div>
  );
}

export default Accomodation;
