import React, { useEffect, useState } from 'react';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import { useParams } from 'react-router-dom';
import axios from 'axios';


function Bands() {
  const { bandname } = useParams();
  const [bandInfo, setBandInfo] = useState(null);

  useEffect(() => {
    axios.get('/bands2024.json')
      .then(response => {
        const bands = response.data;
        const band = bands.find(b => b.name.replace(/\s+/g, '-').toLowerCase() === bandname.toLowerCase());
        setBandInfo(band);
      })
      .catch(error => {
        console.error('Error fetching the band information:', error);
      });
  }, [bandname]);

  if (!bandInfo) {
    return <div>Loading...</div>;
  }

  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={bandInfo.image} className='band-image' alt={bandInfo.name} />
        </div>
      </header>
      <main>
        <section className="lineup-section">
          <h1 className='norse-font'>{bandInfo.name}</h1>
          <p>{bandInfo.info}</p>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Bands;
