import React from 'react';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import './../Information.css';
import informationImage from './area.jpg'; 
import informationImage2 from './area2.jpg'; 
import informationImage3 from './area4.jpg'; 
function FestivalArea() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage2} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>FESTIVAL AREA</h1>
            
          </div>
        </div>
      </header>
      <main>
        <section className="lineup-section">
          <h1 className='norse-font' id="opening-hours">OPENING HOURS</h1>
          
            <p><strong>Wednesday and Thursday</strong> 14:00 - 01:00</p>

            <p><strong>Friday and Saturday</strong> 14:00 - 01:00</p>
        </section>
        <section className="red-section"  style={{ fontSize: '0.8em' }}>
        <h2 className='norse-font'>Festival Guide:<br/> Essential Information for a Smooth Experience</h2>
        <div className="information-grid2">
          <div>
          <h3 className='norse-font'>What to Bring</h3>
          <hr className='sep'></hr>
<p>Check the weather before heading to the festival to determine if you should bring sunscreen and light clothes for a nice day, or warm clothes and a rain poncho for a rainy day.<br/> 
We need to see proof of age so please remember your ID (passport or driver's license)!</p>
<ul>
  <li>Comfortable and sturdy shoes</li>
  <li>Reusable water bottle</li>
  <li>Cash and/or credit cards</li>
  <li>Portable phone charger</li>
  <li>Personal medications and a basic first-aid kit</li>
  <li>Earplugs for hearing protection</li>
  <li>Hand sanitizer and wet wipes</li>
  <li>Small backpack or fanny pack</li>
</ul>
          </div>
          <div>
          <h3 className='norse-font'>What Not to Bring</h3>
          <hr className='sep'></hr>
<p>Note that these rules apply to the festival area. Different rules may apply to the camping area.</p>
<ul>
<li>Large Bags and Backpacks (over 10 liters)</li>
<li>Umbrellas, walking sticks</li>
<li>Skateboards, Scooters, Bicycles, Hoverboards, and Other Similar Items</li>
<li>Helmets</li>
<li>Selfie Sticks, Chairs, Tripods, and Drones</li>
<li>Spray Cans and Flammable Products </li>
<li>Food and Drinks</li>
<li>Spikes, regular pyramid studs on cloting is fine</li>
<li>Cans and Glass Bottles (including glass cups)</li>
<li>Illicit Substances, Over-the-Counter Medicines Without Original Packaging, and Prescribed Medicines Without Prescription</li>
<li>Weapons and Sharp Objects (including clubs, chains, spikes on clothing, and any item that can be used as a weapon)</li>
<li>Explosive Materials (including smoke bombs, flares, pyrotechnic objects, and projectiles)</li>
<li>Fires and Bonfires (including stoves, barbecues, gas stoves, and power generators, except portable chargers)</li>
<li>Musical Instruments, Stadium Trumpets, and Whistles</li>
<li>Audio/Video/Photo Equipment (including professional cameras with detachable lenses)</li>
<li>Flags, Patches, Pins and Banners or anything slightly political</li>
<li>Water Guns</li>
<li>Animals</li>
<li>Any Item Obstructing Visibility or Escape Routes</li>
<li>Any Item That Can Disturb the Peace, Endanger the Public, or Cause Damage (at the sole discretion of the Festival organization team)</li>


</ul>
          </div>
        </div>
        <div className="image-text-container">
          <img src={informationImage3} alt="Information" className="information-image" />
          <div className="information-text">
           
            
            </div></div>
            <div className="information-grid2">
            <div>
            <h3 className='norse-font'>The Festival/Concert Code</h3>
            <hr className='sep'></hr>
            <p>Midgardsblot takes place in Midgard, a place for all human beings, no matter what nationality, cultural background, ethnicity, gender or sexual orientation ❤ Our festival and our community are neutral grounds where we welcome you all to meet, to bond and to build friendship across all borders. A place where we celebrate all our different roots and our diversity so we all can share wisdom, learn from each other and learn to accept our differences. To do so we have a zero tolerance on politics and harrasments of any kind so you must all leave that at our Gates before entering. </p>
<p>Welcome home!</p>

            </div>
            <div>
            <h3 className='norse-font'>Rules of Conduct</h3>
            <hr className='sep'></hr>
<p><strong>Recording:</strong> No commercial recording, photographing, or filming without authorization. Short personal recordings are permitted.</p>
<p><strong>Behavior:</strong> Aggressive or harassing behavior towards staff or attendees is prohibited.</p>
<p><strong>Integrity of Structures:</strong> Do not damage or tamper with event infrastructure.</p>
<p><strong>Climbing:</strong> Climbing on unauthorized structures is not allowed.</p>
<p><strong>Access Routes:</strong> Keep access and escape routes clear.</p>
<p><strong>Intoxication:</strong> Intoxicated or drugged individuals will not be allowed entry.</p>
<p><strong>Thrash:</strong> Dispose of thrash in thrash cans, and cigarette buts in ash trays.</p>
            </div>
           
            </div>



        </section>
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
           
            
            </div></div>
            <section className="red-section">
        <h1 className='norse-font' id="viking-center">Midgard Vikingsenter</h1>
        <p><strong>Midgard Vikingsenter in Borre</strong> is a branch of the Vestfold Museums, and is located next to Northern Europe’s largest assembly of monumental grave mounds from the Germanic Iron Age and the Viking Age. The Centre opened in 2000 with the primary task of creating and spreading knowledge about Viking Age in the Vestfold County.</p>

<p style={{ fontSize: '0.8em' }}>The burial mounds in Borre constitute one of Norway's most important national heritage sites, and it was a place of power and influence in Europe during the Viking Age.</p>

<p style={{ fontSize: '0.8em' }}>The great Viking Hall at Borre is a unique reconstruction of a great mead hall for the Viking aristocracy. The remains of two such halls have recently been discovered just next to the Borre park, indicating that Borre was a center of power already early in the Viking Age. Based on fresh archeological theories on what these halls may have looked like, the construction took several years and cost around 25 million NOK.</p>

<p style={{ fontSize: '0.8em' }}>The rebuilt Gildehall is where the festival area will be. The impressive Feasthall, being 33 meters in length, totally dominates the festival area. Richly carved with period decorations it makes you feel like you have been transported back in time. The great Hall, built to impress and intimidate, was a place for feasts, important decision making and cultic activities: known as Blot. The atmosphere at the plains of Gildehallen is magical with a genuine Norse feel to it which echoes the ancient times. Here is where the Midgardsblot outdoor stage will be with the Beerfestival and the Viking market surrounding it. Midgardsblot invites you to the best of the world of metal in unique and historical surroundings!</p>

          
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default FestivalArea;
