import React from 'react';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import './../Information.css';
import informationImage from './availability.jpg'; 

function Availability() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>AVAILABILITY</h1>
          </div>
        </div>
      </header>
      <main>
        <section className="lineup-section">
          <h1 className='norse-font'>AVAILABILITY AT MIDGARDSBLOT!</h1>
          <p>We work actively to ensure that everyone that wishes to should be able to participate in the festival. We do this by ensuring that the area is adapted in the best way possible, and by giving free entrance to companions by presenting a companion certificate.</p>
          <a href="https://form.arkon.no/?id=10877&key=naaq0n" target="_BLANK" rel="noreferrer" className="read-more-button">Register here</a> 
        </section>
        <section className="red-section">
  
          <h2  className='norse-font'>BRINGING A COMPANION</h2>
          <p style={{ fontSize: '0.8em' }}>If you have a disability and you have a companion with a valid companion ID, they will be allowed to enter for free. The companion needs to register with accreditation when arriving at the festival. You can only have one companion at a time unless your companion ID states otherwise.</p>
        

          <h2  className='norse-font'>ACCREDITATION OPENING HOURS</h2>
          <p style={{ fontSize: '0.8em' }}>Stay tuned for Accreditation opening hours for 2024.</p>

          <h2 className='norse-font'>PARKING</h2>
          <p style={{ fontSize: '0.8em' }}>There are very limited parking spaces adjacent to the museum that are suitable for HC parking, but we will have 5 designated spots where you can park for free from day to day. Remember to bring a valid disability parking card.</p>

          <h2  className='norse-font'>FESTIVAL AREA</h2>
          <p style={{ fontSize: '0.8em' }}>There will be ramps for better vantage points at both Kaupangr and Valhalla stage.</p>

          <h2 className='norse-font'>ENTRANCE</h2>
          <p style={{ fontSize: '0.8em' }}>There will be a separate entrance for people unable to walk on the main entrance on the field. Follow the road and security will let you through.</p>
         
        </section>
       
      </main>
      <Footer />
    </div>
  );
}

export default Availability;
