import React from 'react';
import './Footer.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'; // For free solid icons
import { fab } from '@fortawesome/free-brands-svg-icons'; // For free brand icons
import { far } from '@fortawesome/free-regular-svg-icons'; // For free regular icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(fas, fab, far);
function Footer() {
  const publicUrl = process.env.PUBLIC_URL;

  return (
    <footer className="footer">
      <div className="sponsors">
        <img src={`${publicUrl}/assets/partners/meny.png`} alt="Meny Borreveien" />
        <img src={`${publicUrl}/assets/partners/Grimfrost.png`} alt="Grimfrost" />
        <img src={`${publicUrl}/assets/partners/horten.png`} alt="Horten Kommune" />
        <img src={`${publicUrl}/assets/partners/kultur.png`} alt="Kulturrådet" />
        <img src={`${publicUrl}/assets/partners/MidgardViking.png`} alt="Midgard Vikingsenter" />
        <img src={`${publicUrl}/assets/partners/vestfold.png`} alt="Vestfoldmuseene" />
        <img src={`${publicUrl}/assets/partners/gronnfestival.png`} alt="Grønn Festival" />
        <img src={`${publicUrl}/assets/partners/saxon.png`} alt="Grønn Festival" />
      </div>
      <div className="copyright">
    
        <a href="https://www.facebook.com/midgardsblot" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'facebook']} /> </a>
        <a href="https://www.instagram.com/midgardsblot/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'instagram']} /> </a>
        <a href="https://www.youtube.com/c/MidgardsblotMetalFestival" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'youtube']} /> </a>
      
        <p>&copy; Midgardsblot 2024</p>
      </div>
      <div className="quick-links">
        <a href="https://form.arkon.no/?id=10083&key=ngVvaK" target="_blank" rel="noreferrer">Press</a><br/>
        <a href="mailto:support@midgardsblot.no">Contact</a><br/>
        <a href="/privacypolicy">Privacy Policy</a><br/>
      </div>
    </footer>
  );
}

export default Footer;
