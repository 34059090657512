import React, { useState, useEffect } from 'react';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import informationImage from './schedule.jpg';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'; // For free solid icons
import { far } from '@fortawesome/free-regular-svg-icons'; // For free regular icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
library.add(fas, far);

const stages = {
    wednesday: ['Helheim', 'Valhalla', 'Gildehallen', 'Kaupangr'],
    thursday: ['Helheim', 'Gildehallen', 'Kaupangr'],
    friday: ['Helheim', 'Valhalla', 'Gildehallen', 'Kaupangr'],
    saturday: ['Helheim', 'Valhalla', 'Gildehallen', 'Kaupangr'],
};

const schedule = {
    wednesday: {
        Helheim: [
            { band: 'UADA', from: '16:50', to: '17:45' },
            { band: 'GRAND MAGUS', from: '18:15', to: '19:00' },
            { band: 'KALANDRA', from: '19:30', to: '20:30' },
            { band: 'AMON AMARTH', from: '21:30', to: '23:00' },
        ],
        Valhalla: [
            { band: 'OPENING BLOT', from: '14:45', to: '16:30' },
        ],
        Gildehallen: [
            { band: 'JOHNNY HEXX', from: '19:00', to: '19:30' },
            { band: 'KJELL BRAATEN', from: '20:30', to: '21:15' },
            { band: 'HEILUNG HALL', from: '22:00', to: '00:00' },
        ],
        Kaupangr: [
            
            { band: 'Story Tellling by TIM TALESMAN', from: '19:10', to: '19:25' },
            { band: 'SIGNING SESSION HINDARFJÄLL WITH PETER FRANZEN (GRIMFROST TENT)', from: '20:40', to: '21:10' },
            { band: 'BRANNOS', from: '21:00', to: '21:15' },
            { band: 'METAL DJ: DJ RÆNGRÆNG (HANS FYRSTE)', from: '23:00', to: '01:00' },
        ],
    },
    thursday: {
        Helheim: [
            { band: 'GRIMFROST BATTLE (Front of Stage)', from: '14:30', to: '15:10' },
            
            { band: 'INCULTER', from: '16:45', to: '17:30' },
            { band: 'VADER', from: '18:15', to: '19:15' },
            { band: 'HINDARFJÄLL & Peter Franzen', from: '20:15', to: '21:15' },
            { band: 'TESTAMENT', from: '22:00', to: '23:00' },
        ],
        Gildehallen: [
            { band: 'BÆRZERK', from: '14:30', to: '15:15' },
            { band: 'Nyanser av svart by Harald Fossberg', from: '16:00', to: '16:45' },
            { band: 'PETER FRANZEN - Interview', from: '17:30', to: '18:15' },
            { band: 'TRIO ORO', from: '19:30', to: '20:15' },
            { band: 'MORTAL FEAR', from: '21:15', to: '21:45' },
            { band: 'FOLKET BORTAFOR NORDAVINDEN', from: '22:30', to: '00:00' },
        ],
        Kaupangr: [
            
            { band: 'BRANNOS', from: '14:45', to: '15:00' },
            { band: 'GRIMFROST: Visit Ragnar Lothbroks Viking Ship', from: '15:30', to: '16:30' },
            { band: 'MEAD TASTINGS at Kaupangr Bar', from: '16:00', to: '17:00' },
            { band: 'Story Tellling by TIM TALESMAN', from: '16:10', to: '16:30' },
            { band: 'GRIMFROST HOLMGANGR', from: '17:00', to: '18:00' },
            { band: 'FRIGGS DØTRE', from: '19:10', to: '19:30' },
            { band: 'Story Telling by Gustav Holberg', from: '21:30', to: '21:50' },
            { band: 'METAL DJ: SECTHDAMON', from: '23:00', to: '01:00' },
            
        ],
    },
    friday: {
        Helheim: [
            { band: 'VULTURE INDUSTRIES', from: '15:45', to: '16:45' },
            { band: 'EREB ALTOR', from: '17:30', to: '18:30' },
            { band: 'DESTROYER 666', from: '19:30', to: '20:30' },
            { band: 'SEIGMEN', from: '21:30', to: '23:00' },
        ],
        Valhalla: [
            { band: 'GRIMFROST BATTLE (Front of Stage)', from: '15:15', to: '15:45' },
            { band: 'FOLKET BORTAFOR NORDAVINDEN', from: '16:45', to: '17:30' },
            { band: 'FEN', from: '18:30', to: '19:30' },
            { band: 'NYTT LAND', from: '20:30', to: '21:30' },
            { band: 'SONGLEIKR', from: '23:00', to: '00:00' },
        ],
        Gildehallen: [
            { band: 'EXTREME VOCAL WORKSHOP', from: '14:30', to: '15:00' },
            { band: 'HOW BIRCH TAR CHANGED OUR WORLD', from: '16:00', to: '16:45' },
            { band: 'DOCTORS WITHOUT BORDERS', from: '17:30', to: '18:30' },
            { band: 'FABBRICA 82', from: '19:30', to: '20:30' },
            { band: 'NATTVERD', from: '21:45', to: '22:45' },
        ],
        Kaupangr: [
            
            { band: 'Story Tellling by TIM TALESMAN', from: '15:00', to: '15:20' },
            { band: 'GRIMFROST: Visit Ragnar Lothbroks Viking Ship', from: '15:30', to: '16:30' },
            { band: 'MEAD TASTINGS at Kaupangr Bar', from: '16:00', to: '17:00' },
            { band: 'GRIMFROST HOLMGANGR', from: '17:00', to: '18:00' },
            { band: 'Story Telling by GUSTAV HOLBERG', from: '18:45', to: '18:15' },
            { band: 'FRIGGS DØTRE', from: '20:45', to: '21:05' },
            { band: 'METAL DJ: ASHE DRAVEN', from: '23:00', to: '01:00' },
        ],
    },
    saturday: {
        Helheim: [
            { band: 'TEXAS CORNFLAKES MASSACRE', from: '14:15', to: '15:00' },
            { band: 'LUMSK', from: '15:45', to: '16:45' },
            { band: 'UNLEASHED', from: '17:30', to: '18:30' },
            { band: 'BEL CANTO', from: '19:30', to: '20:30' },
            { band: 'EMPEROR', from: '21:40', to: '23:00' },
        ],
        Valhalla: [
            { band: 'GRIMFROST BATTLE (Front of Stage)', from: '14:15', to: '15:00' },
            { band: 'FOLKET BORTAFOR NORDAVINDEN', from: '15:15', to: '15:45' },
            { band: 'LINDY-FAY HELLA & DEI FARNE', from: '16:45', to: '17:30' },
            { band: 'LEDFOOT', from: '18:30', to: '19:30' },
            { band: 'ELDRIM', from: '20:30', to: '21:30' },
            { band: 'RUNAHILD & ASTRALSEID', from: '23:00', to: '00:00' },
        ],
        Gildehallen: [
            { band: 'FRIGGS DØTRE', from: '14:15', to: '15:00' },
            { band: 'NYTT LAND ACOUSTIC: Siberian Ritual & Lecture ', from: '16:00', to: '16:45' },
            { band: 'SALIGE SYNDERE', from: '17:30', to: '18:15' },
            { band: 'DWAAL', from: '19:45', to: '20:30' },
            { band: 'KARIN PARK', from: '22:00', to: '22:40' },
        ],
        Kaupangr: [
            
            { band: 'Q&A with Astralseid og Runahild ', from: '15:00', to: '15:45' },
            { band: 'GRIMFROST: Visit Ragnar Lothbroks Viking Ship', from: '15:30', to: '16:30' },
            { band: 'GRIMFROST HOLMGANGR', from: '17:00', to: '18:00' },
            { band: 'Story Telling by TIM TALESMAN', from: '18:45', to: '18:15' },
            { band: 'BRANNOS', from: '20:45', to: '21:00' },
            { band: 'METAL DJ: DOEDSADMIRAL', from: '23:00', to: '01:00' },
        ],
    }
};


const Schedule = () => {
    const [selectedBands, setSelectedBands] = useState(() => {
        const saved = localStorage.getItem('selectedBands');
        return saved ? JSON.parse(saved) : {};
    });

    useEffect(() => {
        localStorage.setItem('selectedBands', JSON.stringify(selectedBands));
    }, [selectedBands]);

    const handleSelect = (day, stage, band) => {
        setSelectedBands((prev) => {
            const newSelection = JSON.parse(JSON.stringify(prev)); // Deep copy to ensure state update
            if (!newSelection[day]) newSelection[day] = {};
            if (!newSelection[day][stage]) newSelection[day][stage] = [];

            if (newSelection[day][stage].includes(band)) {
                newSelection[day][stage] = newSelection[day][stage].filter((b) => b !== band);
            } else {
                newSelection[day][stage].push(band);
            }

            console.log('Previous Selection:', prev);
            console.log('Updated Selection:', newSelection);
            return newSelection;
        });
    };

    const isSelected = (day, stage, band) => {
        return selectedBands[day]?.[stage]?.includes(band);
    };

    const renderSchedule = (day) => {
        return stages[day].map((stage) => (
            <div key={stage}>
                <h3>{stage}</h3>
                <ul>
                    {schedule[day][stage].map(({ band, from, to }) => (
                        <li className="list-item" key={band} onClick={() => handleSelect(day, stage, band)} style={{ cursor: 'pointer' }}>
                            <span>
                                {from} - {to} {band} 
                                <FontAwesomeIcon 
                                    icon={isSelected(day, stage, band) ? 'fas fa-heart' : 'far fa-heart'} 
                                    className="icon"
                                />
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        ));
    };

    const renderSelectedBands = () => {
        const daysOrder = ['wednesday', 'thursday', 'friday', 'saturday'];
        const sortedBandsByDay = daysOrder.reduce((acc, day) => {
            if (selectedBands[day]) {
                const bands = [];
                stages[day].forEach((stage) => {
                    if (selectedBands[day][stage]) {
                        selectedBands[day][stage].forEach((band) => {
                            const bandDetails = schedule[day][stage].find(b => b.band === band);
                            if (bandDetails) {
                                bands.push({ ...bandDetails, stage });
                            }
                        });
                    }
                });
                bands.sort((a, b) => a.from.localeCompare(b.from));
                acc[day] = bands;
            }
            return acc;
        }, {});

        return (
            <div className='information-grid3'>
                {Object.keys(sortedBandsByDay).map((day) => (
                    <div key={day}>
                        <h3 className='norse-font'>{day.charAt(0).toUpperCase() + day.slice(1)}</h3>
                        <ul>
                            {sortedBandsByDay[day].map(({ band, from, to, stage }) => (
                                <li key={band}>
                                    {from}-{to} {band} ({stage})
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        );
    };

    console.log('Rendering Schedule Component'); // Log to check re-render

    return (
        <div className="Information">
            <Navbar />
            <header className="Information-header">
                <div className="image-text-container">
                    <img src={informationImage} alt="Information" className="information-image" />
                    <div className="information-text">
                        <h1 className='norse-font'>PROGRAM</h1>
                    </div>
                </div>
            </header>
            
            <main>
            <section className="lineup-section">
          
          
                <h2 className='norse-font'>FESTIVAL AREA OPENING HOURS:</h2>
                <p><strong>OPENS:</strong> 14:00</p>
                <p><strong>CLOSES:</strong> 01:00</p>
              
           
          </section>
                <section className="red-section">
                    <div>
                        {Object.keys(schedule).map((day) => (
                            <div key={day}>
                                <h2 className='norse-font'>{day.charAt(0).toUpperCase() + day.slice(1)}</h2>
                                <hr className='sep'></hr>
                                <div className="lineup-grid-no-background">
                                    {renderSchedule(day)}
                                </div>
                            </div>
                        ))}
                        <h2 className='norse-font'>YOUR PROGRAM</h2>
                        <hr className='sep'></hr>
                        {renderSelectedBands()}
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
};

export default Schedule;
