import React from 'react';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import './../Information.css';
import informationImage2 from './4.jpg'; 
import informationImage from './area4.jpg'; 
function CampGeneral() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>CAMP UTGARD</h1>
          </div>
        </div>
      </header>
      <main>
        <section className="lineup-section">
          <h1 className='norse-font'>GENERAL INFORMATION</h1>
            <p><strong>OPENS:</strong> Monday, August 12 at 15:00</p>
            <p><strong>CLOSES:</strong> Sunday, August 18 at 14:00</p>
          <a href="/information/ticket" target="_BLANK" rel="noreferrer" className="read-more-button">TICKETS HERE</a> 
        </section>
        <section className="red-section">
        <div className="information-grid2" style={{ fontSize: '0.8em' }}>
        <div>
        <h3 className='norse-font'>Location and Overview</h3>
          <hr className='sep'></hr>
<p>Camp Utgard is situated at Prestegårdsstranda, a picturesque area by the beach surrounded by a large forest. The camp is located behind Viking grave mounds and is within a short walking distance to the Midgard Historical Centre and the main festival site at Gildehallen. A scenic 10-minute walk through the park, among the Viking burial mounds, brings you to the heart of the Midgardsblot festival area.</p>

<p>The campground is an excellent place to meet people, relax around a large bonfire each night by the beach, where songs, stories, and camaraderie abound. The area is extremely safe with 24-hour security.</p>

<p>Camping is only available to those with a Festival Pass or Weekend/Day Pass and is restricted to attendees over 18 years old. No pets are allowed.</p>

        </div>
        <div>
        <h3 className='norse-font'>Utgard Camping Tickets</h3>
          <hr className='sep'></hr>
      
<ul>
    <li><strong>Utgard Tent Ticket - 999 NOK:</strong> Valid for 1 tent space from Monday, August 12 to Sunday, August 18.</li>
    <li><strong>Utgard Camping Ticket - 999 NOK:</strong> Valid for 1 person from Monday, August 12 to Sunday, August 18. Must be accompanied by a festival ticket.</li>
    <li><strong>Utgard Camping Ticket - 666 NOK:</strong> Valid for 1 person for 4 days, from Wednesday, August 14 to Sunday, August 18. Must be accompanied by a festival ticket.</li>
</ul>
<p>Example: If two people are sharing a tent, each person needs a camping ticket and one tent ticket.</p>
<p>You need to bring your own tent and camping equipment, and the maximum tent size is 4x4 meters.</p>

        </div>
        </div>
        
        </section>
        <div className="image-text-container">
          <img src={informationImage2} alt="Information" className="information-image" />
          <section className="red-section">
        <div className="information-grid2" style={{ fontSize: '0.8em' }}>
        <div>
        <h3 className='norse-font'>Camp Rules</h3>
          <hr className='sep'></hr>
<ul>
    <li>No glass bottles, power generators, battiers, animals, gas burners, weapons, or sharp objects.</li>
    <li>No tents on access roads or in front of emergency exits.</li>
    <li>Use trash bins and recycle containers.</li>
    <li>No open fires, BBQs, grills, primus, or gas burners allowed.</li>
    <li>Follow instructions from festival staff/security.</li>
    <li>Must be 18 years old and above.</li>
    <li>Hammocks not allowed.</li>
    <li>Take all trash and belongings with you when you leave.</li>
    <li>Quiet Time: Between 04:00 – 08:00</li>
    <li>Respect the quiet zone area.</li>
</ul>
        </div>
        <div>
        <h3 className='norse-font'>Camping Facilities</h3>
          <hr className='sep'></hr>
<h4 className='norse-font'>Bonfires and Grills</h4>
<ul>
    <li>Bonfires: Public bonfires available at the beach.</li>
    <li>Communal Grills: Available for cooking food.</li>
</ul>

<h4 className='norse-font'>Electricity</h4>
<ul>
    <li>Power Outlets: None at the campsite.</li>
    <li>Charger Station: Available for phones, pads, and laptops.</li>
</ul>

<h4 className='norse-font'>Toilets and Showers</h4>
<ul>
    <li>Toilets: With running water, closed between 22:00-08:00 (other toilets available).</li>
    <li>Handicap Toilets: Available at the campsite.</li>
    <li>Showers: Available at Borrehallen, open from 10:00-14:00.</li>
    <li>Paid showers: Borre Familie Camping (Tues-Sat, 13:00-15:00 & 17:00-19:00). Tickets can be bought for 99 NOK at the Info Tent.</li>
</ul>
<h4 className='norse-font'>Other</h4>
<ul>
    <li>Security: Manned by festival staff 24/7</li>
    <li>Lost Items: Misplaced bags and belongings may be searched for safety reasons</li>
    <li>Charging Stations: Phones can be charged in the staff tent by the entrance (bring your own cables). Power banks cannot be charged.</li>
    <li>Primus Storage: Can be stored in the staff tent and used outside the camp (minimum 10 meters from camp fences).</li>
    <li>Valuable Storage: Can be safely stored in the staff tent.</li>
    <li>Tent Space: Maximum tent size is 4x4 meters.</li>
</ul>

        </div>
        </div>
        
        </section>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default CampGeneral;
