import React from 'react';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import './Information.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import informationImage from './information.jpg'; 

library.add(fas, fab, far);

function Information() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>INFORMATION</h1>
          </div>
        </div>
      </header>
      <main>
    
        <section className="lineup-section">
        <h1 className='norse-font'>PRACTICAL INFO</h1>
          <div className="lineup-grid">
            <div>
            <FontAwesomeIcon icon={['fas', 'tent']} /> 
              <h3 className='norse-font'>FESTIVAL AREA</h3>
              <hr className='sep'></hr>
              <p>What you need to know about the festival area!</p>
               <a href="/information/festivalarea" className="read-more-button">Read More</a> 

            </div>
            <div>
            <FontAwesomeIcon icon={['fas', 'map-location-dot']} /> 
              <h3 className='norse-font'>FAQ</h3>
              <hr className='sep'></hr>
              <p>Frequently asked questions can be found answered here</p>
              <a href="/information/faq" className="read-more-button">Read More</a> 
            </div>
            <div>
            <FontAwesomeIcon icon={['fas', 'credit-card']} /> 
              <h3 className='norse-font'>PAYMENT AT THE FESTIVAL</h3>
              <hr className='sep'></hr>
              <p>How to pay while at the festival area</p>
              <a href="/information/payment" className="read-more-button">Read More</a> 
            </div>
          </div>
        </section>
        <section className="info-section">
        <h1 className='norse-font'>ACCESS TO THE FESTIVAL</h1>
          <div className="lineup-grid">
          <div>
            <FontAwesomeIcon icon={['fas', 'ticket']} /> 
              <h3 className='norse-font'>TICKET TO WRISTBAND</h3>
              <hr className='sep'></hr>
              <p>Where and how to exchange your tickets to wristband.</p>
              <a href="/information/wristband" className="read-more-button">Read More</a> 
            </div>
            <div>
            <FontAwesomeIcon icon={['fas', 'ticket']} /> 
              <h3 className='norse-font'>TICKET INFORMATION</h3>
              <hr className='sep'></hr>
              <p>Tickets for Midgardsblot 2024!</p>
              
              <a href="/information/ticket" className="read-more-button">Read More</a> 
            </div>
            <div>
            <FontAwesomeIcon icon={['fas', 'child']} /> 
              <h3 className='norse-font'>CHILDREN</h3>
              <hr className='sep'></hr>
              <p>Want to bring your child to Midgardsblot? </p>
              <a href="/information/children" className="read-more-button">Read More</a> 
            </div>
            <div>
            <FontAwesomeIcon icon={['fas', 'sun']} /> 
              <h3 className='norse-font'>AVAILABILITY</h3>
              <hr className='sep'></hr>
              <p>We work actively to ensure that everyone that wishes to should be able to participate in the festival.</p>
              <a href="/information/availability" className="read-more-button">Read More</a> 
            </div>
          </div>
          </section>
        <section className="lineup-section">
        <h1 className='norse-font'>CAMPING & ACCOMODATION</h1>
        <div className="info-grid">
            <div>
            <FontAwesomeIcon icon={['fas', 'campground']} /> 
            
              <h3 className='norse-font'>CAMP UTGARD</h3>
              <hr className='sep'></hr>
              <p>Information about the festival camp.</p>
              <a href="/information/camp" className="read-more-button">Read More</a> 
            </div>
            <div>
            <FontAwesomeIcon icon={['fas', 'campground']} /> 
            
              <h3 className='norse-font'>BADSTUVOGNA</h3>
              <hr className='sep'></hr>
              <p>Badstuvogna booking</p>
              <a href="https://badstuvogna.no/booking/" className="read-more-button">Read More</a> 
            </div>
            <div>
            <FontAwesomeIcon icon={['fas', 'tents']} /> 
            
              <h3 className='norse-font'>OTHER ACCOMODATION</h3>
              <hr className='sep'></hr>
              <p>Other accomodation options</p>
              <a href="/information/accomodation" className="read-more-button">Read More</a> 
            </div>
            

        </div>
        </section>
        <section className="info-section">
      <h1 className='norse-font'>TRANSPORTATION</h1>
          <div className="info-grid">
          
            <div>
            <FontAwesomeIcon icon={['fas', 'van-shuttle']} /> 
            
              <h3 className='norse-font'>SHUTTLE BUS</h3>
              <hr className='sep'></hr>
              <p>Details about the festival shuttle.</p>
              <a href="/information/shuttle" className="read-more-button">Read More</a> 
            </div>
            <div>
            <FontAwesomeIcon icon={['fas', 'square-parking']} /> 
              <h3 className='norse-font'>PARKING</h3>
              <hr className='sep'></hr>
              <p>Parking information on the site</p>
              <a href="/information/parking" className="read-more-button">Read More</a> 
            </div>
            <div>
            <FontAwesomeIcon icon={['fas', 'train']} /> 
              <h3 className='norse-font'>TRAIN</h3>
              <hr className='sep'></hr>
              <p>Travel to the festival by train.</p>
              <a href="/information/train" className="read-more-button">Read More</a> 
            </div>
            <div>
            <FontAwesomeIcon icon={['fas', 'plane']} /> 
              <h3 className='norse-font'>AIR</h3>
              <hr className='sep'></hr>
              <p>Wonder how to fly in to Midgardsblot?</p>
              <a href="/information/air" className="read-more-button">Read More</a> 
            </div>
            <div>
            <FontAwesomeIcon icon={['fas', 'car']} /> 
              <h3 className='norse-font'>CAR</h3>
              <hr className='sep'></hr>
              <p>Traveling by car to Midgardsblot.</p>
              <a href="/information/car" className="read-more-button">Read More</a> 
            </div>
            <div>
            <FontAwesomeIcon icon={['fas', 'bus']} /> 
              <h3 className='norse-font'>BUS</h3>
              <hr className='sep'></hr>
              <p>Travel by local busses.</p>
              <a href="/information/bus" className="read-more-button">Read More</a> 
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Information;
