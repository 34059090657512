import React from 'react';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import './../Information.css';

import informationImage from './busses.jpg'; 



function Bus() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>Take the Bus to & from the festival!</h1>
          </div>
        </div>
      </header>
      <main>
    
        <section className="lineup-section">
        
        <p>Closest stop to Midgard Viking Center is <strong>"Kirkebakken Borre"</strong> but you can use <strong>"Midgard Historiske Museum (Horten)"</strong> when searching for the best route.</p>
<p>Please note that there is an 8 minute walk from the Viking Center to the bus stop.</p>
<p>Find your bus departure in the VKT Reise app or in the travel planner!</p>
    

<a href="https://www.vkt.no" className="read-more-button">vkt.no</a> 


<p>You can download the "VKT mobilbillett" app in both Google Play & App Store.</p>
<p>Last departures in the evening are listed below, please check app or website for times during the day</p>
    
</section>
<section className="info-section">
<h3>Wednesday</h3>
    <div className='lineup-grid'>
    <div>
        <h4>Line 02 towards Tønsberg</h4>
        <hr className='sep'></hr>
        <p><strong>22:20</strong> from Horten bus station, Borre at 22:32</p>
        <p><strong>23:20</strong> from Horten bus station, Borre at 23:32</p>
        <p><strong>00:20</strong> from Horten bus station, Borre at 00:32</p>
        <p><strong>01:20</strong> from Horten bus station, Borre at 01:32</p>
    </div>
    <div>
        <h4>Line 02 towards Horten</h4>
        <hr className='sep'></hr>
        <p><strong>22:00</strong> from Tønsberg bus station, Borre at 22:21</p>
        <p><strong>23:00</strong> from Tønsberg bus station, Borre at 23:21</p>
        <p><strong>01:00</strong> from Tønsberg bus station, Borre at 01:21</p>
    </div>
    <div>
        <h4>Line 01</h4>
        <hr className='sep'></hr>
        <p>01 to Åsgårdstrand-Tønsberg, from Borre at: 22:23, 23:23</p>
        <p>01 to Horten, from Borre at: 22:39, 23:39, 00:39</p>
    </div>
    </div>
    <h3>Thursday</h3>
    <div className='lineup-grid'>
    
    <div>
        <h4>Line 02 towards Tønsberg</h4>
        <hr className='sep'></hr>
        <p><strong>22:20</strong> from Horten bus station, Borre at 22:32</p>
        <p><strong>23:20</strong> from Horten bus station, Borre at 23:32</p>
        <p><strong>00:20</strong> from Horten bus station, Borre at 00:32</p>
        <p><strong>01:20</strong> from Horten bus station, Borre at 01:32</p>
    </div>
    <div>
        <h4>Line 02 towards Horten</h4>
        <hr className='sep'></hr>
        <p><strong>22:00</strong> from Tønsberg bus station, Borre at 22:21</p>
        <p><strong>23:00</strong> from Tønsberg bus station, Borre at 23:21</p>
        <p><strong>01:00</strong> from Tønsberg bus station, Borre at 01:21</p>
    </div>
    <div>
        <h4>Line 01</h4>
        <hr className='sep'></hr>
        <p>01 to Åsgårdstrand-Tønsberg, from Borre at: 22:23, 23:23</p>
        <p>01 to Horten, from Borre at: 22:39, 23:39, 00:39</p>
    </div>
    </div>

    <h3>Friday</h3>
    <div className='lineup-grid'>
    <div>
        <h4>Line 02 towards Tønsberg</h4>
        <hr className='sep'></hr>
        <p><strong>22:20</strong> from Horten bus station, Borre at 22:32</p>
        <p><strong>23:20</strong> from Horten bus station, Borre at 23:32</p>
        <p><strong>00:20</strong> from Horten bus station, Borre at 00:32</p>
        <p><strong>01:15</strong> from Horten bus station, Borre at 01:27</p>
        <p><strong>02:45</strong> from Horten bus station, Borre at 02:57</p>
    </div>
    <div>
        <h4>Line 02 towards Horten</h4>
        <hr className='sep'></hr>
        <p><strong>22:00</strong> from Tønsberg bus station, Borre at 22:21</p>
        <p><strong>23:00</strong> from Tønsberg bus station, Borre at 23:21</p>
        <p><strong>01:00</strong> from Tønsberg bus station, Borre at 01:21</p>
        <p><strong>02:00</strong> from Nedre Langgate, Borre at 02:26</p>
    </div>
    <div>
        <h4>Line 01</h4>
        <hr className='sep'></hr>
        <p>01 to Åsgårdstrand-Tønsberg, from Borre at: 22:23, 23:23</p>
        <p>01 to Horten, from Borre at: 22:39, 23:39, 00:39</p>
    </div>
    </div>
    <h3>Saturday</h3>
    <div className='lineup-grid'>
    <div>
        <h4>Line 02 towards Tønsberg</h4>
        <hr className='sep'></hr>
        <p><strong>22:20</strong> from Horten bus station, Borre at 22:32</p>
        <p><strong>23:20</strong> from Horten bus station, Borre at 23:32</p>
        <p><strong>00:20</strong> from Horten bus station, Borre at 00:32</p>
        <p><strong>01:15</strong> from Horten bus station, Borre at 01:27</p>
        <p><strong>02:45</strong> from Horten bus station, Borre at 02:57</p>
    </div>
    <div>
        <h4>Line 02 towards Horten</h4>
        <hr className='sep'></hr>
        <p><strong>22:00</strong> from Tønsberg bus station, Borre at 22:21</p>
        <p><strong>23:00</strong> from Tønsberg bus station, Borre at 23:21</p>
        <p><strong>00:00</strong> from Tønsberg bus station, Borre at 00:21</p>
        <p><strong>02:00</strong> from Nedre Langgate, Borre at 02:26</p>
    </div>
    <div>
        <h4>Line 01</h4>
        <hr className='sep'></hr>
        <p>01 to Åsgårdstrand-Tønsberg, from Borre at: 22:23, 23:23</p>
        <p>01 to Horten, from Borre at: 22:39, 23:39, 00:39</p>
    </div>

    </div>
</section>


      </main>
      <Footer />
    </div>
  );
}

export default Bus;
