import React from 'react';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';


import informationImage from './midgard.jpg';

function Midgard() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>MIDGARD TALKS & WALKS</h1>
          </div>
        </div>
      </header>
      <main>
        <section className="lineup-section">
          <h1 className='norse-font'>MIDGARD TALKS &amp; WALKS</h1>
          <p>
            Each day before the bands take to the stages, scholars from international universities and experts from various fields will share their knowledge with visitors during Midgard Talks &amp; Walks.
          </p>
          <p>
            "Midgard Walks and Talks" is a program of guided tours and activities offered by the Midgard Viking Centre during Midgardsblot. These tours, led by expert archaeologists, educate visitors about the rich Viking history of the region.
          </p>
          <p>
            Midgard Talks and Walks fully hosted by <a href="https://vestfoldmuseene.no/midgard-vikingsenter/" target="_blank" rel="noopener noreferrer"><b>Midgard Viking Center</b></a> during the festival. We are sure that the seminars are in the best of hands under the umbrella of the Viking Center.
          </p>
          <p>
            For any questions concerning the program contact the museum on this email: <a href="mailto:Christina.Leverkus@vestfoldmuseene.no">Christina.Leverkus@vestfoldmuseene.no</a>
          </p>
          <a href="https://vestfoldmuseene.no/midgard-vikingsenter/" target="_BLANK" rel="noreferrer" className="read-more-button">Midgard Viking Center</a> 
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Midgard;
